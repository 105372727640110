<template>
  <b-modal
    :id="updateModalId"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯品牌
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 品牌名稱 -->
        <validation-provider
          #default="{ errors }"
          name="品牌名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                品牌名稱
                <span class="text-danger">*</span>
              </label>
            </template>

            <b-form-input
              id="name"
              v-model="brandData.name"
              autofocus
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="brandData.name ? brandData.name : '請輸入管理者名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <div>
          <label>品牌說明</label>
          <b-form-textarea
            v-model="brandData.description"
            :placeholder="brandData.description ? brandData.description : '請輸入品牌說明'"
            :state="brandData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="brandData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ brandData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BFormTextarea,
  VBTooltip, // BInputGroupAppend, BInputGroup, BImg,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
// import BrandExplorerModal from '@/layouts/components/Modal/brand-modal/brandModal.vue'
import { useAdminBrandModal, useModalSetting } from '../useModal'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    // BImg,
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormSpinbutton,

    // vSelect,
    ValidationProvider,
    ValidationObserver,
    // BrandExplorerModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
    updateModalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetBrandData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide(this.updateModalId)
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '品牌名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.brandData[Object.keys(requiredFields)[i]] === null || this.brandData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.brandData.description.length > this.maxChar) {
        this.useAlertToast(false, '品牌說明字數超過上限')
        return
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      if (this.selectedItem.id) {
        this.setBrandUpdate({
          brand_id: this.selectedItem.id,
          data: {
            ...this.brandData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('reset-table')
            this.resetBrandData()
            this.$nextTick(() => {
              this.$bvModal.hide(this.updateModalId)
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setBrandCreate({
          ...this.brandData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('reset-table')
            this.resetBrandData()
            this.$nextTick(() => {
              this.$bvModal.hide(this.updateModalId)
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankBrandData, this.selectedItem),
      }
      this.brandData = resolveData
      this.$bvModal.show(this.updateModalId)
    },
  },
  setup() {
    localize('tw')

    const {
      setBrandCreate,
      setBrandUpdate,
    } = useAdminBrandModal()

    const {
      isBusy,
      syncObject,
    } = useModalSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankBrandData = {
      name: null,
      description: '',
    }

    const brandData = ref(JSON.parse(JSON.stringify(blankBrandData)))

    const resetBrandData = () => {
      brandData.value = JSON.parse(JSON.stringify(blankBrandData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBrandData)

    return {
      isBusy,

      brandData,
      blankBrandData,
      setBrandCreate,
      setBrandUpdate,

      resetBrandData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
