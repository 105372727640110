import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    adminBrand: {},
    statusLists: {},
    statusOptions: [],
    priorityLists: {},
    priorityOptions: [],
    groupOptions: [],
    // customerView: JSON.parse(localStorage.getItem('customer-view')) ? JSON.parse(localStorage.getItem('customer-view')) : {},
    useAudioState: false,
  },
  getters: {},
  mutations: {
    UPDATE_ADMIN_BRAND(state, val) {
      state.adminBrand = val
    },
    UPDATE_PRIORITY_LIST(state, val) {
      state.priorityLists = val
    },
    UPDATE_PRIORITY_OPTIONS(state, val) {
      state.priorityOptions = val
    },
    UPDATE_STATUS_LIST(state, val) {
      state.statusList = val
    },
    UPDATE_STATUS_OPTIONS(state, val) {
      state.statusOptions = val
    },
    UPDATE_GROUP_OPTIONS(state, val) {
      state.groupOptions = val
    },
    UPDATE_USE_AUDIO_STATE(state, val) {
      state.useAudioState = val
    },
  },
  actions: {
    // (查詢)客戶列表
    getCustomerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/customer', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一客戶
    getCustomerData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/${resolveData.customer_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)客戶
    setCustomerCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/customer', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)客戶
    setCustomerUpdate(ctx, resolveData) {
      if (resolveData.customer_id) {
        return new Promise((resolve, reject) => {
          axios(`/admin/customer/${resolveData.customer_id}`, {
            method: 'PATCH',
            headers: {
              'Access-Token': useJwt.getToken(),
            },
            data: {
              ...resolveData.data,
            },
          })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios('/admin/customer', {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)客戶
    setCustomerDelete(ctx, resolveData) {
      if (resolveData.customer_id) {
        return new Promise((resolve, reject) => {
          axios(`/admin/customer/${resolveData.customer_id}`, {
            method: 'DELETE',
            headers: {
              'Access-Token': useJwt.getToken(),
            },
          })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios('/admin/customer', {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)客戶備註列表
    getCustomerRemarkList(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/${resolveData.customer_id}/remarks`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)客戶搜尋
    getCustomerRemarkSearch(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/${resolveData.customer_id}/remarks/search`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)客戶備註
    setCustomerRemarkCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/remarks`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)客戶備註
    setCustomerRemarkUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/remarks/${resolveData.remark_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)客戶備註
    setCustomerRemarkDelete(ctx, resolveData) {
      if (resolveData.type === 'muti') {
        return new Promise((resolve, reject) => {
          axios(`/admin/customer/${resolveData.customer_id}/remarks`, {
            method: 'DELETE',
            headers: {
              'Access-Token': useJwt.getToken(),
            },
            data: {
              ...resolveData.data,
            },
          })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/remarks/${resolveData.remark_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)備註詳情
    getRemarkAppendData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/remarks/${resolveData.remark_id}/appends`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)備註檔案
    setRemarkAppendUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/remarks/${resolveData.remark_id}/appends`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (獲取)備註檔案Key
    getRemarkAppendDownloadKey(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/remarks/${resolveData.remark_id}/appends/${resolveData.append_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)客戶設定
    getCustomerConfigList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/customer/config', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)客戶設定
    setCustomerConfigCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/customer/config', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)客戶設定
    setCustomerConfigUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/config/${resolveData.config_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)客戶設定
    setCustomerConfigDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/config/${resolveData.config_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)客戶大頭貼
    setCustomerProfileUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/image`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)客戶大頭貼
    setCustomerProfileDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/image`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)部門統計
    getLineChart(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/customer/analytics', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ----------------------------------------------------------------
    // (查詢)待辦列表
    getTodoList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/todo', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)待辦
    setTodoCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/todo', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
