<template>
  <div
    class="chat-right-sidebar"
    :class="{'show': shallShowActiveTodoListSidebar}"
  >
    <header class="d-flex align-items-center justify-content-between mx-1 my-2">
      <div>
        <h4 class="chat-user-name mb-0">
          待辦列表
        </h4>
      </div>

      <div
        class="cursor-pointer table-header-close"
        style="height: 15px; width: 20px;"
        @click="$emit('update:shall-show-active-todo-list-sidebar', false)"
      >
        <div class="line" />
        <div class="line" />
      </div>
    </header>

    <hr class="mb-0 dividing-line">

    <div class="contact-area">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area"
      >
        <div class="animate__animated animate__fadeIn">
          <ul
            v-if="customerTodo.data.length > 0"
            class="p-50"
          >
            <customer-todo-contact
              v-for="todo in customerTodo.data"
              :key="todo.id"
              :todo-data="todo"
              tag="li"
              class="d-flex align-items-center todo-contact"
              @submit-show-content="id => {
                targetRouteContent(todo.id)
                todo.has_view = true
              }"
            />
            <div
              v-if="!isLoading && isAll && customerTodo.data.length > 0"
              class="text-center p-1"
            >
              <span>沒有更多資料了</span>
            </div>

            <div
              v-if="!isLoading && !isAll"
              class="text-center text-primary cursor-pointer"
              @click="() => {
                isLoading = true
                $emit('loading-next-todo-page')
              }"
            >
              顯示更多
            </div>
          </ul>

          <div
            v-if="!isLoading && customerTodo.data.length === 0"
            class="text-center p-2"
          >
            <span>暫無待辦事項</span>
          </div>

          <div
            v-if="isLoading"
            class="text-center p-1"
          >
            <b-img
              src="/admin/images/common/loading-2.png"
              rounded
              height="30"
              width="30"
            />
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { VBTooltip, BImg } from 'bootstrap-vue'
import CustomerTodoContact from './CustomerTodoContact.vue'
import { useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    CustomerTodoContact,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    shallShowActiveTodoListSidebar: {
      type: Boolean,
      required: true,
    },
    customerInfo: {
      type: Object,
      required: true,
    },
    customerTodo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    isAll() {
      if (this.customerTodo.data.length < this.customerTodo.total) return false
      return true
    },
  },
  watch: {
    customerTodo: {
      handler() {
        this.isLoading = false
      },
      deep: true,
    },
  },
  methods: {
    moment,
    // (跳轉)待辦列表
    targetRouteContent(id) {
      const targetRoute = { name: 'admin-todo-view', params: { id } }
      const { href } = this.$router.resolve(targetRoute)
      window.open(href, '_blank')
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.header-profile-sidebar {
  .chat-user-name {
    word-break: break-all;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 10px;
  }
}
.dark-layout {
  .contact-area {
    background-color: $theme-dark-body-bg;
  }
  .todo-contact {
    background: $theme-dark-card-bg;
    &:hover {
      background: #24c8ff6a;
    }
  }
}

.contact-area {
  height: calc(100% - 100px);
  background: #dedfe5;
  border-radius: 5px;
  margin: 10px 10px;
  overflow-y: scroll;
  .scroll-area {
    padding: 10px;
  }
}

.todo-contact {
  flex-direction: row;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  background: #fbfbfb;
  &:hover {
    cursor: pointer;
    background: #24c8ff1c;
  }
}
</style>
