<template>
  <!-- 客戶待辦列表 -->
  <div class="active-chat animate__animated animate__fadeIn">
    <!-- {{ isBusy }} -->
    <!-- Header -->
    <div class="chat-navbar">
      <div
        class="chat-header d-flex align-items-center w-100"
      >
        <!-- Search -->
        <b-input-group class="input-group-merge w-100">
          <b-input-group-prepend is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchQuery"
            placeholder="搜尋..."
            debounce="500"
          />
        </b-input-group>

        <div class="sidebar-profile-toggle ml-50 d-flex">
          <!-- <div
            v-b-tooltip.hover.focus.v-secondary
            title="新增"
            class="d-flex align-items-center actions-link-btn ml-25"
            @click="$emit('update:shall-show-active-todo-list-sidebar', true)"
          >
            <b-img
              src="/admin/images/table/plus.svg"
              class="actions-link-btn-image"
              rounded
            />
          </div> -->

          <div
            v-b-tooltip.hover.focus.v-secondary
            title="排序"
            class="d-flex align-items-center actions-link-btn ml-25"
            :class="{ 'rotated': isSortDirDesc }"
            @click="isSortDirDesc = !isSortDirDesc"
          >
            <b-img
              src="/admin/images/todo/action/arrows.svg"
              class="actions-link-btn-image"
              rounded
            />
          </div>

        </div>
      </div>
    </div>

    <!-- 客戶列表 -->
    <vue-perfect-scrollbar
      ref="todoListContent"
      :settings="perfectScrollbarSettings"
      class="scroll-area"
    >
      <!-- chat-user-list-wrapper list-group scroll-area -->
      <div class="animate__animated animate__fadeIn ">
        <ul
          v-if="todoListData.length > 0"
          class="p-1"
        >
          <customer-todo-contact
            v-for="todo in todoListData"
            :key="todo.id"
            :todo-data="todo"
            tag="li"
            class="card todo-contact"
            @submit-show-content="id => {
              targetRouteContent(todo.id)
              todo.has_view = true
            }"
          />
        </ul>

        <div
          v-if="isBusyLoading || isBusy"
          class="text-center p-1"
        >
          <b-img
            src="/admin/images/common/loading-2.png"
            rounded
            height="30"
            width="30"
          />
        </div>

        <div
          v-if="!isBusyLoading && !isAll && todoListData.length !== 0"
          class="text-center p-1"
        >
          <span>沒有更多資料了</span>
        </div>
      </div>
    </vue-perfect-scrollbar>

    <!-- {{ todoListData }} -->
  </div>
</template>

<script>
import {
  // ref,
  computed,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  VBTooltip, BImg,
  BInputGroup, BInputGroupPrepend, BFormInput,
  // BRow, BCol, BAvatar, BTooltip, BButton, BModal, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { $themeBreakpoints } from '@themeConfig'
import { avatarText } from '@core/utils/filter'
import CustomerTodoContact from './CustomerTodoContact.vue'
import {
  useCustomerSetting,
  useCustomerTodoList,
} from '../useCustomer'
// import store from '@/store'
// import router from '@/router'
import { useAlert, useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    CustomerTodoContact,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    customerInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false, // true: disable
      dateRangeText: null, // 時間區間 2023-01-01 to 2023-01-02
      selectedTodo: [],
      searchResults: [],
    }
  },
  computed: {
    isAll() {
      const num = this.currentPage * this.perPage
      if (num > this.totalNum) return false
      return true
    },
  },
  watch: {
    todoListData: {
      handler(newValue) {
        this.$emit('update-todo-list', newValue, this.totalNum)
      },
      deep: true,
    },
  },
  mounted() {
    const todoListContentElement = this.$refs.todoListContent.$el
    todoListContentElement.addEventListener('scroll', this.isScrollBottom)
  },
  methods: {
    moment,
    // (跳轉)待辦列表
    targetRouteContent(id) {
      const targetRoute = { name: 'admin-todo-view', params: { id } }
      const { href } = this.$router.resolve(targetRoute)
      window.open(href, '_blank')
    },

    // (自動)加載資料
    isScrollBottom() {
      const { scrollTop, scrollHeight, clientHeight } = this.$refs.todoListContent.$el
      if (scrollTop + clientHeight === scrollHeight && this.isAll) {
        this.currentPage += 1
      }
    },

    // (初始化)待辦列表
    refreshTodoList() {
      if (this.customerInfo && this.customerInfo.data) {
        if (this.customerInfo.data.id) {
          this.searchCustomer = this.customerInfo.data.id
          this.getTodoListInitData()
        }
      }
    },

    // (下一頁)待辦列表
    refreshTodoListNextPage() {
      this.currentPage += 1
    },
  },
  setup(props) {
    moment.locale('zh-tw')

    // const currentId = router.currentRoute.params.id
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useCustomerSetting()

    const {
      isBusy,
      isBusyLoading,
      todoListData,
      totalNum,
      perPage,
      sortBy,
      isSortDirDesc,
      currentPage,
      searchQuery,
      timeRange,
      timeWarningOptions,
      searchCustomer,
      getTodoListInitData,
      getTodoListNextData,
      refreshTodoListPageData,
      refetchData,
    } = useCustomerTodoList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    searchCustomer.value = props.customerInfo.data.id
    if (searchCustomer.value) getTodoListInitData()

    return {
      isDark,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
      avatarText,

      useAlertToast,
      useHttpCodeAlert,

      isBusy,
      isBusyLoading,
      todoListData,
      totalNum,
      perPage,
      sortBy,
      isSortDirDesc,
      currentPage,
      searchQuery,
      searchCustomer,
      timeRange,
      timeWarningOptions,
      getTodoListInitData,
      getTodoListNextData,
      refreshTodoListPageData,
      refetchData,

      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-context.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes
.dark-layout {
  .scroll-area {
    background-color: $theme-dark-body-bg;
  }
  .active-chat {
    border: 5px solid #344464;
    border-top: none;
    box-shadow: inset 0px 7px 10px 0px $theme-dark-card-bg;
    background-color: $theme-dark-body-bg;
  }
  .todo-contact {
    &:hover {
      background: #24c8ff6a;
    }
  }
}
.active-chat {
  border: 5px solid #fff;
  border-top: none;
  box-shadow: inset 0px 7px 10px 0px #cdd0e0;
}
.todo-contact {
  flex-direction: row;
  padding: 0.5rem;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
    background: #24c8ff1c;
  }
  // color: $theme-dark-body-color;
  // background-color: $theme-dark-body-bg;
}

.scroll-area {
  height: calc(100% - 70px);
  background-color: #dedfe5;
  overflow-y: scroll;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
</style>
