<template>
  <div
    class="chats"
  >
    <div ref="uploadCardContent">
      <div
        ref="uploadCard"
        no-body
        class="image-explorer-contain upload-card"
        @dragenter="dragEnter"
        @dragover="preventDefault"
        @dragleave="dragLeave"
        @drop="dropFile"
      >
        <div
          ref="dropArea"
          class="dropArea hidden"
        >
          <div class="upload-label">
            <span class="label-title">
              把檔案拖放到這裡
            </span>
            <b-img
              :src="require('@/assets/images/icons/image-processing.png')"
              fluid
            />
          </div>
        </div>

        <div>
          <!-- 客戶詳情 -->
          <div>
            <b-card no-body>
              <b-card-header class="pb-50">
                <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
                  <h6
                    v-if="customerInfo.data.admin_brand"
                    class="mb-0 text-primary font-weight-bold cursor-pointer"
                    @click="() => { $emit('emit-update-sidebar')}"
                  >
                    {{ customerInfo.data.admin_brand.name }}
                  </h6>

                  <small
                    v-else
                    class="text-muted mb-0"
                  >
                    公司品牌
                  </small>
                </div>
              </b-card-header>

              <b-card-body class="py-0 d-flex justify-content-between">
                <div class="d-flex  align-items-center">
                  <!-- flex-column flex-md-row -->
                  <div class="mr-1 mb-1">
                    <div class="profile-image">
                      <b-avatar
                        size="60"
                        class="badge-minimal"
                        :src="`${customerInfo.data.image}/sm`"
                        variant="light-secondary"
                      />

                      <div
                        class="profile-image-mask animate__animated animate__fadeIn"
                      >
                        <div
                          class="profile-icon profile-image-edit"
                          @click="profileImageEdit"
                        >
                          <b-img
                            src="/admin/images/table/edit3.svg"
                            width="16"
                            height="16"
                          />
                        </div>
                        <div
                          v-if="customerInfo.data.image"
                          class="profile-icon profile-image-reset"
                          @click="profileImageReset"
                        >
                          <b-img
                            src="/admin/images/table/reset.svg"
                            width="16"
                            height="16"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-1">
                    <b-input-group
                      v-if="editCol === 'name'"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="name"
                        ref="inputName"
                        v-model="customerInfo.data.name"
                        placeholder="客戶名稱"
                      />

                      <b-input-group-append is-text>
                        <div>
                          <b-img
                            src="/admin/images/common/checked-blue.svg"
                            width="18"
                            class="mr-75 cursor-pointer"
                            @click="confirmEditCol('name')"
                          />

                          <b-img
                            src="/admin/images/common/close-blue.svg"
                            width="18"
                            class="cursor-pointer"
                            @click="cancelEditCol"
                          />
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                    <div
                      v-else
                      @click="selectEditCol('name')"
                    >
                      <h4
                        class="mb-0 cursor-pointer customer-text"
                      >{{ customerInfo.data.name }}</h4>

                      <small
                        v-if="customerInfo.data.nickName"
                        class="cursor-pointer"
                      >
                        <span
                          v-for="name, index in customerInfo.data.nickName"
                          :key="name"
                        >
                          {{ name }}<span v-if="index + 1 !== customerInfo.data.nickName.length">, </span>
                        </span>
                      </small>
                      <div class="score-area d-sm-none d-block">
                        <star-rating
                          v-model="customerInfo.data.score"
                          :increment="0.5"
                          :max-rating="5"
                          :grow="10"
                          inactive-color="#8a8a8a"
                          active-color="#Ffd700"
                          border-color="#Ffd700"
                          :star-size="18"
                          text-class="score-text font-weight-bold"
                          @rating-selected="confirmEditCol('score')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-1 d-flex flex-column align-items-end justify-content-between">
                  <div class="mb-1 text-nowrap">
                    <div class="d-flex">
                      <div
                        class="actions-link-btn-sm mr-25"
                        @click="$emit('update:shall-show-active-todo-list-sidebar', true)"
                      >
                        <b-img
                          v-b-tooltip.hover.v-secondary
                          title="待辦事項"
                          src="/admin/images/todo/action/todo.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>

                      <div
                        class="actions-link-btn-sm"
                        @click="() => { $emit('emit-update-sidebar')}"
                      >
                        <b-img
                          v-b-tooltip.hover.v-secondary
                          title="編輯"
                          src="/admin/images/todo/action/pen.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-none d-sm-block">
                    <div class="d-flex justify-content-end align-items-center">
                      <small class="text-nowrap mb-0 mt-25 mr-50">
                        評價
                      </small>
                      <div class="score-area">
                        <star-rating
                          v-model="customerInfo.data.score"
                          :increment="0.5"
                          :max-rating="5"
                          :grow="10"
                          inactive-color="#8a8a8a"
                          active-color="#Ffd700"
                          border-color="#Ffd700"
                          :star-size="18"
                          text-class="score-text font-weight-bold"
                          @rating-selected="confirmEditCol('score')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <b-card-body class="email-detail-head py-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label-cols="4"
                      label="電子信箱"
                      class="m-0 selection-group"
                    >
                      <div class="mt-50">
                        {{ customerInfo.data.email }}
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  />

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label-cols="4"
                      label="地址"
                      class="m-0 selection-group"
                    >
                      <div class="mt-50">
                        <span v-if="customerInfo.data.address">
                          {{ customerInfo.data.address }}
                        </span>
                        <span v-else>---</span>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label-cols="4"
                      label="國家"
                      class="m-0 selection-group"
                    >
                      <div class="mt-50">
                        <span v-if="customerInfo.data.country">
                          {{ customerInfo.data.country }}
                        </span>
                        <span v-else>---</span>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label-cols="4"
                      label="創建時間"
                      class="m-0 selection-group"
                    >
                      <div class="mt-50">
                        <div v-if="customerInfo.data.created_at">
                          <span
                            v-b-tooltip.hover.focus.v-secondary
                            :title="`${moment(customerInfo.data.created_at).format('HH:mm')}`"
                          >
                            {{ updateOnline(customerInfo.data.created_at, refonlineTime) }}
                          </span>
                        </div>

                        <div v-else>
                          <span>---</span>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label-cols="4"
                      label="上次更新"
                      class="m-0 selection-group"
                    >
                      <div class="mt-50">
                        <div v-if="customerInfo.data.updated_at">
                          <span
                            v-b-tooltip.hover.focus.v-secondary
                            :title="`${moment(customerInfo.data.updated_at).format('HH:mm')}`"
                          >
                            {{ updateOnline(customerInfo.data.updated_at, refonlineTime) }}
                          </span>
                        </div>

                        <div v-else>
                          <span>---</span>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-card-body>

              <hr>

              <b-card-body
                class="pt-0 pb-50 cursor-pointer d-flex justify-content-between align-items-center"
                @click="showMore = !showMore"
              >
                <div>
                  <h6 class="text-body mb-0">
                    其他資訊
                  </h6>
                </div>
                <div class="text-right show-description-arrow">
                  {{ !showMore ? '顯示更多' : '收起' }}
                  <feather-icon
                    :icon="'ChevronRightIcon'"
                    size="20"
                    class="align-bottom cursor-pointer"
                    :class="{ 'active': showMore }"
                    @click="() => activeChat = {}"
                  />
                </div>
              </b-card-body>

              <b-card-body
                class="pt-0"
                :class="{ 'pb-50': !showMore }"
              >
                <b-collapse
                  v-model="showMore"
                  class="mt-50"
                >
                  <div
                    v-if="customerInfo.state"
                    class="p-50"
                  >
                    <div
                      v-if="!isInfoLoading && customerInfo.data"
                    >
                      <b-form-group
                        label-cols="2"
                        label="備註說明"
                        class="m-0 selection-group"
                      >
                        <div class="mt-50">
                          <b-input-group
                            v-if="editCol === 'remark'"
                            class="input-group-merge"
                          >
                            <b-form-input
                              id="remark"
                              ref="customerRemark"
                              v-model="customerInfo.data.remark"
                              placeholder="備註說明"
                            />

                            <b-input-group-append is-text>
                              <div>
                                <b-img
                                  src="/admin/images/common/checked-blue.svg"
                                  width="18"
                                  class="mr-75 cursor-pointer"
                                  @click="confirmEditCol('remark')"
                                />

                                <b-img
                                  src="/admin/images/common/close-blue.svg"
                                  width="18"
                                  class="cursor-pointer"
                                  @click="cancelEditCol"
                                />
                              </div>
                            </b-input-group-append>
                          </b-input-group>

                          <div
                            v-else
                            v-b-tooltip.hover.focus.leftbottom
                            primary="secondary"
                            title="點擊編輯"
                            @click="selectEditCol('remark')"
                          >
                            <span
                              v-if="customerInfo.data.remark"
                              class="mb-0 cursor-pointer customer-text"
                            >
                              {{ customerInfo.data.remark }}
                            </span>
                            <span v-else>---</span>
                          </div>

                        </div>
                      </b-form-group>

                      <b-form-group
                        label-cols="2"
                        label="聯絡方式"
                        class="m-0 selection-group"
                      >
                        <div
                          v-if="!customerInfo.data.contact_method"
                          class="mt-50"
                        >
                          <span class="mb-0 customer-text">
                            暫無聯絡方式
                          </span>
                        </div>
                        <div
                          v-else
                        >
                          <div class="d-flex flex-wrap">
                            <div
                              v-for="contact in customerInfo.data.contact_method"
                              :key="contact.method"
                              v-b-tooltip.hover.v-secondary
                              :title="contact.value"
                              class="actions-link-btn"
                            >
                              <b-badge
                                variant="light-primary"
                              >
                                {{ contact.method }}
                              </b-badge>
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                    </div>

                    <div
                      v-else
                      class="animate__animated animate__fadeIn"
                    >
                      <div class="loading-area-editor text-center">
                        <b-img
                          src="/admin/images/common/loading-2.png"
                          rounded
                          height="30"
                          width="30"
                        />
                      </div>
                    </div>
                  </div>
                </b-collapse>
                <!-- <div class="d-flex align-items-center mb-1">
                  <feather-icon
                    icon="BoxIcon"
                    size="14"
                  />
                  <h6 class="text-body mb-0 ml-50">
                    客戶說明
                  </h6>
                </div> -->

              </b-card-body>
            </b-card>
          </div>

          <!-- 備註 -->
          <div
            v-if="!isRemarkLoading"
            class="msg-list animate__animated animate__fadeIn"
          >
            <!-- <span>{{ formattedChatData }}</span> -->
            <template v-if="formattedChatData.length > 0">
              <div
                v-for="(msgGrp) in formattedChatData"
                :key="msgGrp.id"
                class="chat"
                :class="{'chat-left': msgGrp.userId && (msgGrp.userId !== profileUserData.id)}"
              >
                <div
                  v-if="msgGrp.showTime"
                  class="w-100 text-center"
                >
                  {{ moment(msgGrp.time).format('MM/DD') }}
                </div>
                <!-- 其他人備註 -->
                <div
                  v-if="msgGrp.userId && (msgGrp.userId !== profileUserData.id)"
                  class="chat-avatar"
                >
                  <b-avatar
                    size="36"
                    class="avatar-border-2 box-shadow-1"
                    :src="msgGrp.user_info.image"
                    variant="primary"
                  />
                  <div
                    v-b-tooltip.hover.v-secondary
                    :title="ui.onlineStatus[0][msgGrp.user_info.online_status]"
                    class="remark-user-state state-dot"
                    :class="ui.onlineStatus[1][msgGrp.user_info.online_status]"
                  />
                  <!-- variant="transparent" -->
                </div>
                <!-- 自己備註 -->
                <div
                  v-if="msgGrp.userId && (msgGrp.userId !== profileUserData.id)"
                  class="show-text"
                >
                  <span class="ml-1">{{ msgGrp.user_info.name }}</span>
                </div>
                <!-- 訊息 -->
                <div class="chat-body mr-0">
                  <div
                    v-for="msgData in msgGrp.messages"
                    :id="`remark-${msgData.id}`"
                    :key="msgData.id"
                    v-contextmenu:msg-menu-action
                    class="chat-content p-0"
                    :class="{ 'cursor-pointer': selectedRemarkList.length > 0 }"
                    @contextmenu.prevent="(event) => {
                      rightClick = msgData
                    }"
                    @click="() => {
                      if (selectedRemarkList.length === 0) return
                      if (msgGrp.userId && (msgGrp.userId !== profileUserData.id)) return
                      submitSelectedRemark(msgData.id)
                    }"
                  >
                    <!-- $refs['msg-menu-action'].open(event) -->
                    <!-- if(msgData.user_id === profileUserData.id) -->
                    <!-- animate__animated animate__backOutLeft -->
                    <!-- msg-menu-action -->
                    <!-- {{ msgData }} -->
                    <div v-if="type = resolveMegType(msgData.message, msgData.appends.length, msgData.body.images.length)">
                      <div
                        v-if="msgData.body.reply_id"
                        class="reply-area"
                        @click="$emit('emit-scroll-remark-data', msgData.body.reply_id)"
                      >
                        <div>
                          <small class="text-primary">{{ replyData(msgData.body.reply_id).user_info.name }}</small>
                        </div>
                        <div>{{ replyData(msgData.body.reply_id).message }}</div>
                        <!-- msgData.body.reply_id -->
                      </div>

                      <div
                        v-if="type === 'type1'"
                        class="px-1 pt-1"
                      >
                        <p
                          class="customer-text"
                          v-html="formatMessage(msgData.message)"
                        />
                      </div>

                      <div v-if="type === 'type2'">
                        <div class="p-1">
                          <b-row v-if="msgData.body.images.length === 1">
                            <b-col
                              v-for="(image, index) in msgData.body.images"
                              :key="index"
                              cols="12"
                              @click="showImgs(msgData.body.images, index)"
                            >
                              <div class="cursor-pointer image-area">
                                <b-img-lazy
                                  :src="image.preview_image"
                                  fluid-grow
                                  blank-height="200"
                                  :offset="200"
                                  block
                                  @dragover.prevent
                                />
                                <!-- :src="image.preview_image" -->
                              </div>
                            </b-col>
                          </b-row>

                          <b-row v-if="msgData.body.images.length === 2">
                            <b-col
                              v-for="(image, index) in msgData.body.images"
                              :key="index"
                              cols="12"
                              sm="6"
                              class="mb-50"
                              @click="showImgs(msgData.body.images, index)"
                            >
                              <div class="cursor-pointer image-area">
                                <b-img-lazy
                                  :src="image.preview_image"
                                  fluid-grow
                                  blank-height="200"
                                  :offset="200"
                                  block
                                  @dragover.prevent
                                />
                                <!-- :src="image.preview_image" -->
                              </div>
                            </b-col>
                          </b-row>

                          <b-row v-if="msgData.body.images.length >= 3">
                            <b-col
                              v-for="(image, index) in filteredImages(msgData.body.images)"
                              :key="index"
                              cols="12"
                              md="4"
                              sm="12"
                              class="mb-50 more-images-area cursor-pointer"
                              @click="showImgs(msgData.body.images, index)"
                            >
                              <b-img-lazy
                                :src="image.preview_image"
                                fluid-grow
                                blank-height="200"
                                :offset="200"
                                class="more-images image-area"
                                block
                                @dragover.prevent
                              />
                              <span
                                v-if="index === 2 && msgData.body.images.length > 3"
                                class="more-images-filter"
                              >
                                + {{ msgData.body.images.length - 2 }}
                              </span>
                            </b-col>
                          </b-row>
                        </div>

                        <div v-if="msgData.message">
                          <hr class="m-0">
                          <!-- <p class="px-1 pt-1 text-truncate customer-text">
                            {{ msgData.message }}
                          </p> -->
                          <p
                            class="px-1 pt-1 text-truncate customer-text"
                            v-html="formatMessage(msgData.message)"
                          />
                        </div>
                      </div>

                      <div v-if="type === 'type3'">
                        <div class="p-1">
                          <b-media
                            v-for="(append, index) in msgData.appends"
                            :key="append.id"
                            no-body
                            class=""
                            :class="{'mb-2': msgData.appends.length !== index + 1 }"
                          >
                            <b-media-aside class="media-left mr-50">
                              <b-avatar
                                size="40"
                                variant="light-primary"
                              >
                                <feather-icon
                                  v-if="!append.rid"
                                  size="21"
                                  icon="FolderIcon"
                                />
                                <b-img
                                  v-else
                                  src="/admin/images/common/loading-2.png"
                                  height="30"
                                  width="30"
                                />
                              </b-avatar>
                            </b-media-aside>

                            <b-media-body>
                              <div>
                                <h5 class="mb-25">
                                  <div class="customer-text">
                                    {{ resolveFileNameLong(append.file_name, 10) }}
                                  </div>
                                </h5>
                                <span class="text-muted">{{ convertBytes(append.size ? append.size : 0) }}</span>
                              </div>

                              <div>
                                <small
                                  v-if="!siteLock"
                                  class="text-primary cursor-pointer"
                                  @click="submitDownloadAppendKey(msgData, append.id)"
                                >
                                  下載
                                </small>

                                <small
                                  v-else
                                  class="text-muted"
                                >
                                  {{ append.id === siteLock ? '下載中' : '下載' }}
                                </small>
                              </div>
                            </b-media-body>
                          </b-media>
                        </div>
                        <div v-if="msgData.message">
                          <hr class="m-0">
                          <!-- <p class="px-1 pt-1 text-truncate customer-text">
                            {{ msgData.message }}
                          </p> -->
                          <p
                            class="px-1 pt-1 customer-text"
                            v-html="formatMessage(msgData.message)"
                          />
                        </div>
                      </div>

                      <!-- {{ msgData }} -->
                    </div>

                    <div
                      v-else
                      class="p-1 text-muted"
                    >
                      空訊息
                    </div>

                    <div
                      v-if="resolveMegType(msgData.message, msgData.appends.length, msgData.body.images.length)"
                      class="text-right text-muted px-50 pb-25 ml-1"
                    >
                      <small>
                        {{ `${moment(msgData.created_at).format('a hh')}:${moment(msgData.created_at).format('mm')}` }}

                        <!-- {{ msgData.rid }} -->
                        <span v-if="msgGrp.userId === profileUserData.id">
                          <b-img
                            v-if="!msgData.rid"
                            src="/admin/images/todo/action/sent.svg"
                            width="12"
                          />

                          <feather-icon
                            v-else
                            icon="ClockIcon"
                            size="10"
                            style="margin-top: -3px"
                          />
                        </span>
                      </small>
                    </div>

                    <div
                      v-if="isIndexInSelectedRemark(msgData.id)"
                      class="chat-content-selected-area"
                    />

                    <div
                      v-if="msgData.id && msgData.id === searchPoint"
                      class="chat-content-search-area"
                    />
                  </div>
                </div>
              </div>
            </template>

            <!-- 不知道為甚麼，但這一行得保留才正常 -->
            <span v-show="false">{{ formattedChatData.length }}</span>

            <!-- <vue-context
              ref="msg-menu-action"
              :height-offset="150"
            /> -->

            <v-contextmenu
              ref="msg-menu-action"
              :class="$store.state.appConfig.layout.skin === 'dark' ? 'my-contextmenu-dark' : 'my-contextmenu'"
            >
              <div>
                <v-contextmenu-item
                  class="dropdown-item-area"
                  @click="submitReplyRemark"
                >
                  <b-img
                    src="/admin/images/todo/action/reply.svg"
                    class="dropdown-item-btn-image mr-50"
                    rounded
                  />
                  <span>回覆</span>
                </v-contextmenu-item>

                <v-contextmenu-item
                  class="dropdown-item-area"
                  @click="submitCopyRemark"
                >
                  <b-img
                    src="/admin/images/todo/action/copy.svg"
                    class="dropdown-item-btn-image mr-50"
                    rounded
                  />
                  <span>複製文字</span>
                </v-contextmenu-item>

                <div v-if="rightClick.user_id === profileUserData.id">

                  <v-contextmenu-item
                    class="dropdown-item-area"
                    @click="submitEditRemark({})"
                  >
                    <b-img
                      src="/admin/images/todo/action/pen.svg"
                      class="dropdown-item-btn-image mr-50"
                      rounded
                    />
                    <span>編輯</span>
                  </v-contextmenu-item>

                  <v-contextmenu-item
                    class="dropdown-item-area"
                    @click="submitDeleteRemark"
                  >
                    <b-img
                      src="/admin/images/todo/action/trash-bin.svg"
                      class="dropdown-item-btn-image mr-50"
                      rounded
                    />
                    <span>刪除</span>
                  </v-contextmenu-item>

                  <v-contextmenu-item
                    class="dropdown-item-area"
                    @click="submitSelectedRemark(rightClick.id)"
                  >
                    <b-img
                      src="/admin/images/todo/action/check1.svg"
                      class="dropdown-item-btn-image mr-50"
                      rounded
                    />
                    <span>{{ isIndexInSelectedRemark(rightClick.id) ? '取消選取' : '選取訊息' }}</span>
                  </v-contextmenu-item>
                </div>
              </div>
            </v-contextmenu>
          </div>

          <!-- 備註讀取動畫 -->
          <div
            v-show="isRemarkLoading"
            class="msg-list animate__animated animate__fadeIn"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              style="height: 300px;"
            >
              <div class="wrapper">
                <div class="circle" />
                <div class="circle" />
                <div class="circle" />
                <div class="shadow" />
                <div class="shadow" />
                <div class="shadow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 大頭貼 -->
    <b-modal
      id="customerProfileModal"
      no-close-on-backdrop
      centered
      size="sm"
      @hidden="handleHideProfileModal"
      @close="handleHideProfileModal"
    >
      <template #modal-title>
        <h4
          v-if="customerInfo.data.image"
          class="m-0"
        >
          更換大頭貼
        </h4>
        <h4
          v-else
          class="m-0"
        >
          上傳大頭貼
        </h4>
      </template>

      <div
        v-if="customerInfo.data && previewImage"
        class="site-modal"
      >
        <div v-if="!isImageBusy">
          <div
            class="my-2 d-flex justify-content-center"
          >
            <div class="site-modal-images">
              <div
                v-if="previewImage.src"
                class="site-modal-tool"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="16"
                  class="site-modal-tool-icon"
                  @click="submitDeleteImage()"
                />
              </div>

              <b-avatar
                :src="previewImage.src"
                fluid
                size="100"
                class="profile-modal-image"
              />
            </div>
          </div>

          <input
            ref="imageInput"
            type="file"
            hidden
            @input="changeImage($event)"
          >
        </div>
        <div
          v-else
          class="text-center p-2"
        >
          <b-img
            src="/admin/images/common/loading-2.png"
            rounded
            height="50"
            width="50"
          />
        </div>

      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <div>
            <b-button
              variant="flat-primary"
              @click="$refs.imageInput.click()"
            >
              上傳
            </b-button>
          </div>

          <div>
            <b-button
              variant="flat-primary"
              :disabled="isRemarkBusy"
              @click="handleHideProfileModal"
            >
              取消
            </b-button>

            <b-button
              variant="flat-primary"
              :disabled="isRemarkBusy"
              @click="handleConfirmProfileModal"
            >
              確認
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <!-- 備註留言 -->
    <b-modal
      id="customerSiteModal"
      no-close-on-backdrop
      centered
      @hidden="handleHideSiteModal"
      @close="handleHideSiteModal"
    >
      <template #modal-title>
        <h4 class="m-0">
          <div v-if="previewFile.length === 1">
            傳送{{ siteType === 'image' ? '圖片' : '檔案' }}
          </div>

          <div v-else>
            已選擇{{ previewFile.length }}個{{ siteType === 'image' ? '圖片' : '檔案' }}
          </div>
        </h4>
      </template>

      <div class="site-modal">
        <div v-if="siteType === 'image'">
          <b-row v-if="previewFile.length >=2">
            <b-col
              v-for="(image, index) in previewFile"
              :key="index"
              cols="12"
              lg="6"
              class="mb-1"
            >
              <div class="site-modal-images">
                <div class="site-modal-tool">
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="16"
                    class="site-modal-tool-icon mr-1"
                    @click="handleChangeSite(index)"
                  />

                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="site-modal-tool-icon"
                    @click="submitDeleteAppend(index)"
                  />
                </div>

                <b-img
                  :src="image.src"
                  fluid
                  class="site-modal-image"
                />
              </div>
            </b-col>
          </b-row>

          <b-row v-if="previewFile.length ===1">
            <b-col
              v-for="(image, index) in previewFile"
              :key="index"
              cols="12"
              lg="12"
              class="mb-1"
            >
              <div class="site-modal-images">
                <div class="site-modal-tool">
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="16"
                    class="site-modal-tool-icon mr-1"
                  />

                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="site-modal-tool-icon"
                    @click="submitDeleteAppend(index)"
                  />
                </div>

                <b-img
                  :src="image.src"
                  fluid
                  class="site-modal-image"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="siteType === 'file'">
          <b-media
            v-for="(append, index) in previewFile"
            :key="index"
            no-body
            class=""
            :class="{'mb-2': previewFile.length !== index + 1 }"
          >
            <b-media-aside class="media-left mr-50">
              <b-avatar
                class="avatar"
                size="40"
                variant="light-primary"
              >
                <feather-icon
                  size="21"
                  icon="FolderIcon"
                />
              </b-avatar>
            </b-media-aside>

            <b-media-body>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mb-25">
                    <div>
                      {{ resolveFileNameLong(append.file.name, 10) }}
                    </div>
                  </h5>
                  <span class="text-muted">{{ convertBytes(append.file.size ? append.file.size : 0) }}</span>
                </div>

                <div class="ml-1 site-modal-files">
                  <div class="site-modal-tool">
                    <feather-icon
                      icon="RefreshCcwIcon"
                      size="16"
                      class="site-modal-tool-icon mr-1"
                      @click="handleChangeSite(index)"
                    />

                    <feather-icon
                      icon="Trash2Icon"
                      size="16"
                      class="site-modal-tool-icon"
                      @click="submitDeleteAppend(index)"
                    />
                  </div>
                </div>
              </div>
            </b-media-body>
          </b-media>
        </div>

        <input
          ref="fileChange"
          type="file"
          hidden
          @input="changeFile($event)"
        >
      </div>

      <div class="input-area">
        <label>說明文字</label>
        <div class="textarea-section">
          <textarea
            ref="resizeTextarea"
            v-model="siteInput"
            class="w-100 site-input-area"
            :style="{ 'color': !isDark ? '#000' : '#d0d2d6'}"
            @paste="handlePasteSiteModal"
            @input="adjustTextareaHeight"
            @keydown="handleKeyDownSiteModal"
          />

          <div class="emoji-area">
            <my-emoji-component @select-emoji="selectEmojiIconSiteModal">
              <label
                slot="button-content"
                class="cursor-pointer"
              >
                <feather-icon
                  icon="SmileIcon"
                  size="20"
                  class="emoji-icon"
                />
              </label>
            </my-emoji-component>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <div>
            <b-button
              variant="flat-primary"
              :disabled="isRemarkBusy"
              @click="$refs.fileInput.click()"
            >
              新增
            </b-button>

            <input
              ref="fileInput"
              type="file"
              multiple
              hidden
              @input="handleAddSiteModal($event)"
            >
          </div>

          <div>
            <b-button
              variant="flat-primary"
              :disabled="isRemarkBusy"
              @click="handleHideSiteModal"
            >
              取消
            </b-button>

            <b-button
              variant="flat-primary"
              :disabled="isRemarkBusy"
              @click="handleConfirmSiteModal"
            >
              確認
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <!-- 編輯訊息 -->
    <b-modal
      id="customerMessageModal"
      no-close-on-backdrop
      centered
      body-class="p-0"
      @hidden="handleHideMessageModal"
      @close="handleHideMessageModal"
    >
      <template #modal-title>
        <h4 class="m-0">
          編輯訊息
        </h4>
      </template>

      <div class="mt-1">
        <div class="input-area">
          <div class="textarea-section">
            <textarea
              ref="resizeTextareaMessage"
              v-model="inputMessage"
              class="w-100 message-input-area pl-2"
              :style="{ 'color': !isDark ? '#000' : '#d0d2d6'}"
              @input="adjustTextareaMessageHeight"
              @keydown="handleKeyDownMessageModal"
            />
            <div class="emoji-area">
              <my-emoji-component @select-emoji="selectEmojiIconMessageModal">
                <label
                  slot="button-content"
                  class="cursor-pointer"
                >
                  <feather-icon
                    icon="SmileIcon"
                    size="20"
                    class="emoji-icon"
                  />
                </label>
              </my-emoji-component>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            :disabled="isRemarkBusy"
            @click="handleHideMessageModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isRemarkBusy"
            @click="handleConfirmMessageModal"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- 刪除提示彈窗 -->
    <b-modal
      id="customerDeleteModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div class="p-1">
        <h4>你想要刪除這則備註嗎?</h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeDeleteModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isRemarkBusy"
            @click="confirmDeleteModal"
          >
            刪除
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import {
  BCard, BImg, BModal, BButton, BCardBody, BCardHeader, BRow, BCol, BFormGroup, BCollapse, BBadge,
  VBTooltip, BAvatar, BMedia, BMediaAside, BMediaBody, BImgLazy, BFormInput, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import moment from 'moment'
import StarRating from 'vue-star-rating'
import MyEmojiComponent from '@/layouts/components/EmojiPicker/EmojiPicker.vue'
import {
  useCustomerSetting, useRemarkList, useCustomerView, useCustomerList,
} from '../useCustomer'
import { useAlert, useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BBadge,
    BModal,
    BButton,
    BCardBody,
    BFormGroup,
    BCollapse,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImgLazy,
    BCardHeader,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,

    StarRating,
    MyEmojiComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    customerInfo: {
      type: Object,
      required: true,
    },
    customerRemark: {
      type: Array,
      required: true,
    },
    customerInput: {
      type: String,
      required: true,
    },
    selectedRemarkList: {
      type: Array,
      required: true,
    },
    randomKey: {
      type: String,
      default: null,
    },
    isInfoLoading: {
      type: Boolean,
      required: true,
    },
    isRemarkLoading: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    searchPoint: {
      type: Number || null,
      default: null,
    },
    replyId: {
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      editCol: '',
      editColData: '',
      showDescription: false,
      showMore: false,
      siteFile: [],
      siteInput: null,
      siteType: 'image',
      siteIndex: null,
      siteLock: null,
      siteSize: 15728640,
      inputMessage: '',
      previewFile: [],
      previewImage: null,
      isImageBusy: false,
      rightClick: {},
    }
  },
  computed: {
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    formatMessage(message) {
      // let replacedText = message
      // // eslint-disable-next-line
      // const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
      // replacedText = message.replace(replacePattern1, '<a href="$1" target="_blank"">$1</a>')
      // // onclick="return false

      // // eslint-disable-next-line
      // const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
      // replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank"">$2</a>')

      // // eslint-disable-next-line
      // const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
      // replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1"">$1</a>')
      // return replacedText.replace(/\n/g, '<br>')

      let replacedText = message

      // 將 HTML 標籤轉換為純文字
      replacedText = replacedText.replace(/</g, '&lt;')
      replacedText = replacedText.replace(/>/g, '&gt;')

      // 將超連結轉換成可點擊的連結
      // eslint-disable-next-line
      const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
      replacedText = replacedText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

      // eslint-disable-next-line
      const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>')

      // eslint-disable-next-line
      const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

      // 將換行符號轉換成<br>標籤
      replacedText = replacedText.replace(/\n/g, '<br>')

      return replacedText
    },
    // 用戶燈號
    userOnlineStatus(value) {
      if (value === 'online') {
        return ' dot-green'
      }
      if (value === 'busy') {
        return ' dot-yellow'
      }
      return ' dot-gray'
    },

    // (判斷顯示模式)
    resolveMegType(msg, appends, images) {
      if (msg) {
        if (appends) return 'type3'
        if (images) return 'type2'
        return 'type1'
      }

      if (!msg) {
        if (appends) return 'type3'
        if (images) return 'type2'
      }
      return null
    },

    // (轉化)檔案大小
    convertBytes(bytes) {
      const kilobytes = (bytes / 1024).toFixed(1)
      const gigabytes = (kilobytes / (1024 * 1024)).toFixed(1)
      const megabytes = (kilobytes / 1024).toFixed(1)

      if (kilobytes >= 1024 * 1024) return `${gigabytes.toLocaleString()} GB`
      if (kilobytes >= 1024) return `${megabytes.toLocaleString()} MB`
      return `${kilobytes.toLocaleString()} KB`
    },

    // (顯示)圖片數量
    filteredImages(array) {
      const newArray = array
      return newArray.slice(0, 3)
    },

    // (顯示)檔案字數
    resolveFileNameLong(text, length) {
      if (text && text.length > length * 2) {
        const start = text.slice(0, length)
        const end = text.slice(-length)
        return `${start}...${end}`
      }
      return text
    },

    // (關閉)右鍵選擇動作
    closeAction() {
      if (!this.$refs['msg-menu-action']) return
      this.$refs['msg-menu-action'].hide()
    },

    // (轉成)Base64
    readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
      })
    },

    // (觸發)圖片顯示
    showImgs(array, index) {
      if (this.selectedRemarkList.length > 0) return
      const imageList = array
      const newArray = imageList.slice(index).concat(imageList.slice(0, index)).map(item => item.preview_image)
      this.$viewerApi({
        images: newArray,
        options: {
          movable: false,
        },
      })
    },

    // (判斷)顯示模式
    resolveReplyMegType(msg, appends, images) {
      if (msg) {
        return this.resolveFileNameLong(msg, 20)
      }

      if (!msg) {
        if (images) return '回覆圖片備註'
        if (appends) return '回覆檔案備註'
      }
      return null
    },

    // (顯示回覆)
    replyData(id) {
      if (this.customerRemark.length <= 0) return null
      const foundRemark = this.customerRemark.find(remark => remark.id === id)
      if (foundRemark) {
        const resolve = {
          id,
          message: this.resolveReplyMegType(foundRemark.message, foundRemark.appends.length, foundRemark.body.images.length),
          user_id: foundRemark.user_id,
          user_info: foundRemark.user_info,
        }
        return resolve
      }

      return {
        id: null,
        message: '已刪除的備註',
        user_id: null,
        user_info: {
          name: null,
          image: null,
        },
      }
    },

    // ------------------------------------------------------------------------------------
    // (選取)編輯欄位
    selectEditCol(type) {
      if (!this.customerInfo.data) return
      this.editCol = type
      this.editColData = this.customerInfo.data[type]
      if (type === 'name') setTimeout(() => { this.$refs.inputName.focus() }, 200)
      if (type === 'remark') setTimeout(() => { this.$refs.customerRemark.focus() }, 200)
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.customerInfo.data[this.editCol] = this.editColData
      this.editCol = ''
    },

    // (確認)編輯欄位
    confirmEditCol(key) {
      if (!this.customerInfo.data) return
      this.setCustomerUpdate({
        customer_id: this.customerInfo.data.id,
        data: {
          [key]: this.customerInfo.data[key],
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          // this.$emit('emit-refresh-customer', this.customerInfo.data.id)
          this.$emit('update-refresh', this.customerInfo.data.id)
          this.editCol = ''
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '更新失敗!', error.response.data.message)
          this.$emit('emit-update-busy', false)
          this.cancelEditCol()
        })
    },

    // ------------------------------------------------------------------------------------
    preventDefault(e) {
      e.stopPropagation()
      e.preventDefault()
    },

    dragEnter(e) {
      this.preventDefault(e)
      this.elementNode = e.target
      const { types } = e.dataTransfer
      if (types.includes('Files')) {
        this.$refs.dropArea.classList.remove('hidden')
      }
    },

    dragLeave(e) {
      this.preventDefault(e)
      if (this.elementNode === e.target) this.$refs.dropArea.classList.add('hidden')
    },

    dropFile(e) {
      this.preventDefault(e)
      const { files, types } = e.dataTransfer
      this.siteFile = Object.values(files)

      if (!types.includes('Files')) return
      if (this.siteFile === 0) return

      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
        else if (file.size > this.siteSize) this.siteType = 'file'
        else {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
        }
        this.previewFile.push(previewList)
      })
      this.siteInput = JSON.parse(JSON.stringify(this.customerInput))
      this.$bvModal.show('customerSiteModal')
      this.$refs.dropArea.classList.add('hidden')
      setTimeout(() => {
        this.$refs.resizeTextarea.focus()
      }, 100)
    },

    // (匯入)檔案(V)
    importFile(e) {
      const { files } = e
      this.siteFile = Object.values(files)
      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
        else if (file.size > this.siteSize) this.siteType = 'file'
        else {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
        }
        this.previewFile.push(previewList)
      })
      this.siteInput = JSON.parse(JSON.stringify(this.customerInput))
      this.$bvModal.show('customerSiteModal')
      setTimeout(() => {
        this.$refs.resizeTextarea.focus()
      }, 100)
    },

    // (變更)檔案(V)
    changeFile(e) {
      const { files } = e.target
      const [file] = files
      const previewList = {
        file,
        src: null,
      }
      if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
      else if (file.size > this.siteSize) this.siteType = 'file'
      else {
        const objectURL = window.URL.createObjectURL(file)
        previewList.src = objectURL
      }
      this.previewFile.splice(this.siteIndex, 1, previewList)
    },

    // (觸發)變更檔案(V)
    handleChangeSite(index) {
      if (index === null) return
      this.$refs.fileChange.click()
      this.siteIndex = index
    },

    // (新增)檔案(V)
    handleAddSiteModal(e) {
      const { files } = e.target
      this.siteFile.push(Object.values(files))
      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
        else if (file.size > this.siteSize) this.siteType = 'file'
        else {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
        }
        this.previewFile.push(previewList)
      })
    },

    // (匯入)檔案(V)
    importPasteFile(e) {
      const { files } = e
      this.siteFile = Object.values(files)
      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
        else if (file.size > this.siteSize) this.siteType = 'file'
        else {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
        }
        this.previewFile.push(previewList)
      })
    },

    // (調整)備註檔案輸入欄位
    adjustTextareaHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.resizeTextarea
        textarea.style.height = '30px'
        if (textarea.scrollHeight < 100) {
          textarea.style.height = `${textarea.scrollHeight}px`
        } else {
          textarea.style.height = '100px'
        }
      })
    },

    // (觸發)輸入欄位按鈕
    handleKeyDownSiteModal(event) {
      const textareaRef = this.$refs.resizeTextarea
      const cursorPosition = textareaRef.selectionStart
      if (event.shiftKey && event.key === 'Enter') {
        this.siteInput = `${this.siteInput.slice(0, cursorPosition)}\n${this.siteInput.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaHeight()
        }, 100)
      }
      if (event.ctrlKey && event.key === 'Enter') {
        this.siteInput = `${this.siteInput.slice(0, cursorPosition)}\n${this.siteInput.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaHeight()
        }, 100)
      }
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.handleConfirmSiteModal()
      }
    },

    // (觸發)貼上
    handlePasteSiteModal() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.read()
        .then(clipboardItems => {
          clipboardItems.forEach(clipboardItem => {
            const { types } = clipboardItem
            if (types.length === 1) {
              const type = types[0]
              if (type === 'image/png' || type === 'image/jpeg') {
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_image.png', { type: blob.type })
                  this.importPasteFile({ files: [file] })
                })
              }
            }
            if (types.length === 2) {
              if (types.includes('text/html') && types.includes('image/png')) {
                const type = types[1]
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_html_image.png', { type: blob.type })
                  this.importPasteFile({ files: [file] })
                })
              }
            }
          })
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (新增)EMOJI
    selectEmojiIconSiteModal(emoji) {
      const textareaRef = this.$refs.resizeTextarea
      const cursorPosition = textareaRef.selectionStart
      this.siteInput = `${this.siteInput.slice(0, cursorPosition)}${emoji}${this.siteInput.slice(cursorPosition)}`
      const newCursorPosition = cursorPosition + emoji.length
      setTimeout(() => {
        textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
      }, 100)
    },

    // (關閉)彈窗
    handleHideSiteModal() {
      if (this.isRemarkBusy) return
      this.$bvModal.hide('customerSiteModal')
      this.siteType = 'image'
      this.siteFile = []
      this.previewFile = []
    },

    // (確認)彈窗
    async handleConfirmSiteModal() {
      if (this.siteType === 'image') {
        this.isRemarkBusy = true
        // 圖片轉檔
        const sendImageInput = {
          message: this.siteInput,
          images: [],
          reply_id: this.replyId,
        }
        try {
          const base64Array = await Promise.all(
            this.previewFile.map(file => this.readFileAsBase64(file.file)),
          )

          sendImageInput.images = base64Array
        } catch (error) {
          // console.error(error)
        }

        // 預覽圖片
        const previewImageMessage = {
          message: this.siteInput,
          body: {
            images: [],
          },
          user_id: this.$store.state.app.userData.id,
        }
        const images = sendImageInput.images.map(item => {
          const resolveImage = this.syncObject(this.blankImageData, { preview_image: item })
          return resolveImage
        })
        previewImageMessage.body.images = images
        this.$emit('create-remark-data', previewImageMessage)

        this.$bvModal.hide('customerSiteModal')
        this.siteType = 'image'
        this.siteFile = []
        this.previewFile = []

        this.setCustomerRemarkCreate({
          customer_id: this.customerInfo.data.id,
          data: {
            ...sendImageInput,
          },
        })
          .then(response => {
            const { data } = response.data
            const resolveResponse = this.resolveRemarkInfoData({ ...data })
            const newImages = resolveResponse.body.images.map((item, index) => {
              const imageData = this.syncObject(this.blankImageData, item)
              imageData.preview_image = sendImageInput.images[index]
              return imageData
            })
            resolveResponse.body.images = newImages
            resolveResponse.user.name = this.profileUserData.name
            this.$emit('update-remark-data', this.randomKey, resolveResponse)

            this.$store.dispatch('app/isSocketFunctionExist', 'customerCreateRemark')
              .then(state => {
                if (state) {
                  window.socket.customerCreateRemark({
                    customer_id: this.customerInfo.data.id,
                    remark_data: {
                      ...resolveResponse,
                    },
                  })
                }
              })
            this.isRemarkBusy = false
          })
          .catch(() => {
            this.$emit('delete-remark-data', this.randomKey)
            this.isRemarkBusy = false
          })
      }
      if (this.siteType === 'file') {
        this.isRemarkBusy = true
        // 預覽圖片
        const previewAppendMessage = {
          message: this.siteInput,
          appends: [],
          user_id: this.$store.state.app.userData.id,
        }

        this.previewFile.forEach(fileItem => {
          const { file } = fileItem
          const rId = this.generateRandomString()
          const resolveData = {
            rid: rId,
            name: file.name,
            size: file.size,
            file,
          }
          const resolveAppend = this.syncObject(this.blankAppendData, resolveData)
          previewAppendMessage.appends.push(resolveAppend)
          this.$bvModal.hide('customerSiteModal')
        })
        this.$emit('create-remark-data', previewAppendMessage)

        this.setCustomerRemarkCreate({
          customer_id: this.customerInfo.data.id,
          data: {
            message: this.siteInput,
            reply_id: this.replyId,
          },
        })
          .then(response => {
            const { data } = response.data
            const { id } = data

            const currentRandomKey = this.randomKey

            const resolveResponse = this.resolveRemarkInfoData({ ...data, rid: currentRandomKey })
            resolveResponse.appends = previewAppendMessage.appends
            this.$emit('update-remark-data', currentRandomKey, resolveResponse)
            this.isRemarkBusy = false

            resolveResponse.appends.forEach(fileItem => {
              const { file } = fileItem

              const formData = new FormData()
              formData.append('data', file)

              this.setRemarkAppendUpload({
                remark_id: id,
                data: formData,
              })
                .then(upload => {
                  this.$emit('update-remark-append', currentRandomKey, fileItem.rid, upload.data.data, resolveResponse)
                })
            })
            this.isRemarkBusy = false
            this.handleHideSiteModal()
          })
          .catch(() => { this.isRemarkBusy = false })
      }
    },

    // ------------------------------------編輯備註文字-----------------------------------------
    // (觸發)編輯備註文字
    submitEditRemark(remark) {
      if (remark.id) this.rightClick = JSON.parse(JSON.stringify(remark))
      const { message } = this.rightClick
      this.inputMessage = message
      this.$bvModal.show('customerMessageModal')
      setTimeout(() => {
        this.adjustTextareaMessageHeight()
        this.$refs.resizeTextareaMessage.focus()
      }, 500)
    },

    // (關閉)編輯彈窗
    handleHideMessageModal() {
      if (this.isRemarkBusy) return
      this.$bvModal.hide('customerMessageModal')
    },

    // (調整)備註文字輸入欄位
    adjustTextareaMessageHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.resizeTextareaMessage
        textarea.style.height = '30px'
        if (textarea.scrollHeight < 400) {
          textarea.style.height = `${textarea.scrollHeight}px`
        } else {
          textarea.style.height = '400px'
        }
      })
    },

    // (觸發)輸入欄位按鈕
    handleKeyDownMessageModal(event) {
      const textareaRef = this.$refs.resizeTextareaMessage
      const cursorPosition = textareaRef.selectionStart
      if (event.shiftKey && event.key === 'Enter') {
        this.inputMessage = `${this.inputMessage.slice(0, cursorPosition)}\n${this.inputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaMessageHeight()
        }, 100)
      }
      if (event.ctrlKey && event.key === 'Enter') {
        this.inputMessage = `${this.inputMessage.slice(0, cursorPosition)}\n${this.inputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaMessageHeight()
        }, 100)
      }
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.handleConfirmMessageModal()
      }
    },

    // (新增)EMOJI
    selectEmojiIconMessageModal(emoji) {
      const textareaRef = this.$refs.resizeTextareaMessage
      const cursorPosition = textareaRef.selectionStart
      this.inputMessage = `${this.inputMessage.slice(0, cursorPosition)}${emoji}${this.inputMessage.slice(cursorPosition)}`
      const newCursorPosition = cursorPosition + emoji.length
      setTimeout(() => {
        textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
      }, 100)
    },

    // (確認)編輯彈窗
    handleConfirmMessageModal() {
      this.$emit('update-remark-message', 'edit', this.rightClick.id, this.inputMessage)
      this.handleHideMessageModal()
      if (this.rightClick.user_id !== this.profileUserData.id) return
      this.setCustomerRemarkUpdate({
        customer_id: this.customerInfo.data.id,
        remark_id: this.rightClick.id,
        data: {
          message: this.inputMessage,
        },
      })
        .then(response => {
          const { data } = response.data
          const { id } = data

          this.$emit('update-remark-message', 'real', id, data)
          this.isRemarkBusy = false
        })
        .catch(error => {
          this.isRemarkBusy = false
          this.$emit('update-remark-message', 'error', this.rightClick.id, {
            error: error.response,
            message: this.rightClick.message,
            input: this.inputMessage,
          })
        })
    },
    // ------------------------------------------------------------------------------------
    // (觸發)下載附件
    submitDownloadAppendKey(remark, id) {
      if (!remark.id) return
      if (!id) return
      if (this.siteLock) return
      if (this.selectedRemarkList.length > 0) return
      this.siteLock = id
      this.getRemarkAppendDownloadKey({
        remark_id: remark.id,
        append_id: id,
      })
        .then(response => {
          const { key } = response.data.data
          // 創建一個<a>元素
          const link = document.createElement('a')
          // http://192.168.1.139
          link.href = `/file/download/customer/remark/append?key=${key}`
          // 模擬點擊下載連結
          link.click()
          // window.open(`/file/download/remark/append?key=${key}`, '_blank')
          this.siteLock = null
        })
        .catch(error => {
          this.siteLock = null
          this.useHttpCodeAlert(error.response)
        })
    },

    // (觸發)刪除附件
    submitDeleteAppend(index) {
      this.previewFile.splice(index, 1)
      if (this.previewFile.length === 0) this.handleHideSiteModal()
    },

    // ------------------------------------------------------------------------------------
    // (觸發)複製客戶序號
    submitCopySerial() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const { serialNumber } = this.customerInfo.data
      if (!serialNumber) return
      navigator.clipboard.writeText(`#${serialNumber}`)
        .then(() => {
          this.useAlertToast(true, '備註序號已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (觸發)選取備註
    submitSelectedRemark(id) {
      this.$emit('selected-remark-index', id)
    },

    // (是否)選取
    isIndexInSelectedRemark(id) {
      return this.selectedRemarkList.includes(id)
    },

    // (觸發)回覆
    submitReplyRemark() {
      const { id } = this.rightClick
      if (!id) return
      this.$emit('reply-remark-data', id)
    },

    // (觸發)複製備註文字
    submitCopyRemark() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const { message } = this.rightClick
      if (!message) return
      navigator.clipboard.writeText(message)
        .then(() => {
          this.useAlertToast(true, '備註文字已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (觸發)刪除備註
    submitDeleteRemark() {
      if (this.rightClick.user_id !== this.profileUserData.id) return
      if (this.selectedRemarkList.length > 0) {
        this.submitSelectedRemark(this.rightClick.id)
        return
      }
      this.$bvModal.show('customerDeleteModal')
    },

    // (觸發)關閉備註
    closeDeleteModal() {
      this.rightClick = {}
      this.$bvModal.hide('customerDeleteModal')
    },

    // (刪除)備註
    confirmDeleteModal() {
      if (this.rightClick.user_id !== this.profileUserData.id) return
      this.isRemarkBusy = true
      this.setCustomerRemarkDelete({
        customer_id: this.customerInfo.data.id,
        remark_id: this.rightClick.id,
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          const findRemarkIndex = this.customerRemark.findIndex(el => el.id === this.rightClick.id)
          this.$emit('remove-remark-index', findRemarkIndex)
          this.isRemarkBusy = false
          this.closeDeleteModal()
        })
        .catch(error => {
          this.isRemarkBusy = false
          this.useHttpCodeAlert(error.response)
        })
    },

    // ------------------------------------------------------------------------------------
    // (更新)大頭貼
    profileImageEdit() {
      this.previewImage = {
        src: this.customerInfo.data.image ? `${this.customerInfo.data.image}/sm` : '',
        file: null,
      }
      this.$bvModal.show('customerProfileModal')
    },
    // (變更)大頭貼
    changeImage(e) {
      const { files } = e.target
      const [file] = files
      // const judgeImageOption = [
      //   'image/png',
      //   'image/jpeg',
      //   'image/webp',
      //   'image/svg+xml',
      // ]
      // if (!judgeImageOption.includes(file.type)) {
      //   this.useSwalAlertWarning('請上傳圖片檔', '')
      //   return
      // }
      const objectURL = window.URL.createObjectURL(file)
      this.previewImage = {
        src: objectURL,
        file,
      }
    },
    // (觸發)刪除大頭貼
    submitDeleteImage() {
      this.previewImage = {
        src: '',
        file: null,
      }
    },
    // (確認)彈窗
    handleConfirmProfileModal() {
      if (!this.customerInfo.data.image && !this.previewImage.file) {
        this.$bvModal.hide('customerProfileModal')
        return
      }

      if (this.customerInfo.data.image) {
        if (!this.previewImage.file && !this.previewImage.src) {
          this.profileImageReset()
          return
        }
      }
      const formData = new FormData()
      formData.append('data', this.previewImage.file)
      this.isImageBusy = true
      this.setCustomerProfileUpdate({
        customer_id: this.customerInfo.data.id,
        data: formData,
      })
        .then(response => {
          this.useSwalAlertCenter(response.data.success, response.data.message, `${this.customerInfo.data.name}的大頭貼已更新`)
          this.$emit('update-refresh', this.customerInfo.data.id)
          this.isImageBusy = false
          this.$bvModal.hide('customerProfileModal')
          this.previewImage = null
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
          this.isImageBusy = false
          this.$bvModal.hide('customerProfileModal')
        })
    },
    // (關閉)彈窗
    handleHideProfileModal() {
      this.$bvModal.hide('customerProfileModal')
      this.previewImage = null
    },
    // (重設)大頭貼
    profileImageReset() {
      this.useSwalAlertWarning('重設大頭貼', '你確定要移除目前的大頭貼嗎?')
        .then(result => {
          if (result.value) {
            this.setCustomerProfileDelete({
              customer_id: this.customerInfo.data.id,
            })
              .then(() => {
                this.$emit('update-refresh', this.customerInfo.data.id)
                this.useSwalAlertCenter(true, '刪除成功!', '客戶大頭貼已移除')
                this.$bvModal.hide('customerProfileModal')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

  },
  setup(props) {
    moment.locale('zh-tw')
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      ui,
      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useCustomerSetting()

    const {
      setCustomerUpdate,
    } = useCustomerList()

    const {
      isImageBusy,
      setCustomerProfileUpdate,
      setCustomerProfileDelete,
    } = useCustomerView()

    const {
      randomIdArray,
      isRemarkBusy,
      blankAppendData,
      blankImageData,
      judgeTypeOption,
      resolveRemarkInfoData,
      generateRandomString,
      removeRandomIdArray,
      isSameDay,
      setCustomerRemarkCreate,
      setCustomerRemarkUpdate,
      setCustomerRemarkDelete,

      getRemarkAppendData,
      setRemarkAppendUpload,
      getRemarkAppendDownloadKey,
    } = useRemarkList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const formattedChatData = computed(() => {
      let chatLog = []
      chatLog = JSON.parse(JSON.stringify(props.customerRemark))

      const formattedChatLog = []
      const [firstLog] = chatLog
      let chatMessageSenderId = firstLog ? firstLog.user_id : undefined
      let chatMessageSenderTime = firstLog ? firstLog.created_at : undefined

      let msgGroup = {
        userId: chatMessageSenderId,
        user_info: {
          name: firstLog ? firstLog.user.name : null,
          image: firstLog ? firstLog.user.image : null,
          online_status: firstLog ? firstLog.user.online_status : null,
        },
        time: chatMessageSenderTime,
        showTime: true,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        const resolveData = resolveRemarkInfoData(msg)
        if (chatMessageSenderId === msg.user_id && isSameDay(msg.created_at, chatMessageSenderTime)) {
          msgGroup.messages.push(resolveData)
        } else if (!isSameDay(chatMessageSenderTime, msg.created_at)) {
          chatMessageSenderTime = msg.created_at
          chatMessageSenderId = msg.user_id
          formattedChatLog.push(msgGroup)
          msgGroup = {
            userId: msg.user_id,
            user_info: {
              name: msg.user.name,
              image: msg.user.image ? msg.user.image : null,
              online_status: msg.user.online_status ? msg.user.online_status : 'offline',
            },
            time: msg.created_at,
            showTime: true,
            messages: [resolveData],
          }
        } else {
          chatMessageSenderTime = msg.created_at
          chatMessageSenderId = msg.user_id
          formattedChatLog.push(msgGroup)
          msgGroup = {
            userId: msg.user_id,
            user_info: {
              name: msg.user.name,
              image: null,
              online_status: msg.user.online_status ? msg.user.online_status : 'offline',
            },
            time: msg.created_at,
            showTime: false,
            messages: [resolveData],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })
      return formattedChatLog || []
    })

    return {
      ui,
      isDark,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,

      formattedChatData,
      judgeTypeOption,
      randomIdArray,
      isRemarkBusy,
      isImageBusy,
      blankAppendData,
      blankImageData,

      setCustomerRemarkCreate,
      setCustomerRemarkUpdate,
      setCustomerRemarkDelete,
      setCustomerProfileUpdate,
      setCustomerProfileDelete,
      getRemarkAppendData,
      setRemarkAppendUpload,
      getRemarkAppendDownloadKey,
      setCustomerUpdate,
      resolveRemarkInfoData,
      generateRandomString,
      removeRandomIdArray,

      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-context.scss';
.score-area {
  display: flex;
  .score-text {
    width: 22px;
  }
}
</style>

<style lang="scss" scoped>
.customer-text {
  word-break: break-all;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.2; /* 你想要的行高，可以自行調整 */
  overflow: hidden;
}

.profile-image {
  position: relative;
  .profile-image-mask {
    display: none;
    position: absolute;
    z-index: 1;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000033;
    user-select: none;
    .profile-icon {
      cursor: pointer;
    }
    .profile-image-reset {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px #ffffffbd solid;
      background-color: #ffffffbd;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    .profile-image-mask {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.chat-avatar {
  position: relative;
  .remark-user-state {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.contact-method {
  position: relative;
  margin-right: 1rem;
  .contact-icon {
    filter: grayscale(1);
  }
  .contact-input {
    position: absolute;
    opacity: 0;
  }
  &:active {
    .contact-icon {
      filter: none;
    }
    .contact-input {
      position: relative;
      opacity: 1;
    }
  }
}

.reply-area {
  margin: 8px 8px 0 8px;
  padding: 5px 10px;
  background-color: #afb1b439;
  border-radius: 3px 6px;
  border-left: 2px solid #419fd984;
  cursor: pointer;
  min-width: 100px;
}
.hover-content {
  cursor: pointer;
  border-radius: 10px;
  .hover-content-editor {
    min-height: 50px;
  }
}
.link-card {
  border-radius: 5px;
  .link-card-image {
    width: 16px;
    height: 16px;
  }

  &:hover {
    cursor: pointer;
    .link-card-image {
      animation: breathe .8s linear infinite;
    }
  }
}

@keyframes breathe {
  0%{ transform: scale(.97); }
  50%{ transform: scale(1.1); }
  100%{ transform: scale(.97); }
}

.show-description-arrow {
  transition: transform 0.3s ease-in-out;
  .active {
    transition: transform 0.3s ease-in-out;
    transform: rotate(-90deg);
  }
}

.upload-card {
  position: relative;
  min-height: 70vh;
  .dropArea {
    min-height: 50%;
    position: sticky;
    z-index: 1000;
    padding: 30px;
    top: -30px;
    bottom: -30px;
    right: -30px;
    left: -30px;
    background-color: #282828;
    min-height: 90vh;
    opacity: .9;
    text-align: center;
    align-self: center;
    color: #fff;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 40vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 2rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
  }

  .upload-label-text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 900;
    padding: 1rem 0 0.5rem;
  }
}
.site-input-area {
  background: transparent;
  border: none;
  border-radius: 0;
  resize: none;
  height: 30px;
  color: #d0d2d6;
  &:focus-visible {
    outline: none;
  }
}

.message-input-area {
  height: 30px;
  border: none;
  background: transparent;
  border-radius: 0;
  resize: none;
  color: #d0d2d6;
  &:focus-visible {
    outline: none;
  }
}

.more-images-area {
  position: relative;
  .more-images {
    cursor: pointer;
  }
  .more-images-filter {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    background-color: rgba(53, 53, 53, 0.2);
    backdrop-filter: blur(1px);
    color: rgba(255, 255, 255, 0.857);
    font-weight: 600;
  }
}

.chat-content {
  position: relative;
  .image-area {
    max-height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
  pointer-events: none;
  }

  .chat-content-selected-area {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(108, 196, 255, 0.2);
    border-radius: 5px;
  }

  .chat-content-search-area {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 208, 1, 0.2);
    border-radius: 5px;
  }
}
.chat-content-rtl {
  transform: scaleX(-1);
}

.site-modal {
  .site-modal-images {
    .site-modal-tool {
      position: absolute;
      padding: 5px 10px;
      background-color: rgba(51, 51, 51, 0.59);
      border-radius: 5px;
      color: white;
      top: 5%;
      right: 8%;
      .site-modal-tool-icon {
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .site-modal-files {
    .site-modal-tool {
      .site-modal-tool-icon {
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.input-area {
  border-bottom: 2px solid #419fd9;
}

.textarea-section {
  position: relative;
  .emoji-area {
    position: absolute;
    bottom: 0;
    right: 8px;
    margin-top: 20px;
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  .dropdown-item-btn-image {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-customer-dropdown .8s linear infinite;
    }
  }
}

@keyframes btn-breathe-customer-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}
</style>

<style lang="scss" scoped>
.wrapper {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #abd5ee;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 .5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(147, 147, 147, 0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: .2s
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}
</style>
