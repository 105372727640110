<template>
  <div
    class="chat-profile-sidebar"
    :class="{'show': shallOpenCustomerAddSidebarState}"
  >
    <!-- Header -->
    <header class="d-flex align-items-center justify-content-between mx-1 my-2">
      <div>
        <h4 class="chat-user-name mb-0">
          新增客戶
        </h4>
      </div>

      <div
        class="cursor-pointer table-header-close"
        style="height: 15px; width: 20px;"
        @click="$emit('close-sidebar')"
      >
        <div class="line" />
        <div class="line" />
      </div>
    </header>

    <hr class="mb-0 dividing-line">

    <div class="d-flex align-items-center justify-content-between flex-column scroll-area w-100">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
        class="w-100"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <app-collapse
            accordion
            class="mb-1 box-shadow-none"
          >
            <app-collapse-item
              title="基本設定"
              is-visible
              class="box-shadow-none"
            >

              <!-- 客戶名稱 -->
              <validation-provider
                #default="{ errors }"
                name="客戶名稱"
                rules="required"
              >
                <b-form-group label-for="name">
                  <template #label>
                    <label class="mb-0">
                      客戶名稱
                      <span class="text-danger">*</span>
                    </label>
                  </template>
                  <b-form-input
                    id="name"
                    ref="nameInput"
                    v-model="customerData.name"
                    trim
                    :state="errors.length > 0 ? false : null"
                    placeholder="請輸入客戶名稱"
                  />
                </b-form-group>
              </validation-provider>

              <!-- 電子信箱 -->
              <validation-provider
                #default="{ errors }"
                name="電子信箱"
                rules="email"
              >
                <b-form-group label-for="email">
                  <template #label>
                    <label class="mb-0">
                      電子信箱
                    </label>
                  </template>
                  <b-form-input
                    id="email"
                    ref="emailInput"
                    v-model="customerData.email"
                    :state="errors.length > 0 ? false : null"
                    trim
                    placeholder="請輸入電子信箱"
                  />
                </b-form-group>
              </validation-provider>

              <!-- 負責品牌 -->
              <validation-provider
                name="負責品牌"
              >
                <b-form-group label-for="admin_brand_id">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">負責品牌</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="公司品牌設定"
                          class="d-flex align-items-center actions-link-btn-sm"
                          @click="() => { $refs.adminBrandExplorerAddModal.getData(null) }"
                        >
                          <b-img
                            src="/admin/images/table/settings.svg"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <v-select
                    v-model="customerData.admin_brand_id"
                    :options="adminBrandOptions"
                    :clearable="false"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇品牌"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>

              <!-- 別名 -->
              <b-form-group
                label-for="nickName"
              >
                <label class="mb-0 pb-0 d-flex align-items-center">
                  別名
                  <!-- (新增)聯絡方式 -->
                  <div
                    class="add-contact-btn"
                    @click="addName"
                  >
                    <b-img
                      width="18"
                      class=""
                      src="/admin/images/table/plus.svg"
                    />
                  </div>
                </label>
                <div>
                  <b-row
                    v-for="name, index in customerData.nickName"
                    :key="index"
                    class="mb-1"
                  >
                    <b-col
                      cols="10"
                      class="pr-0"
                    >
                      <b-form-input
                        v-model="customerData.nickName[index]"
                        placeholder="請輸入別名"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="d-flex align-items-center"
                    >
                      <div
                        class="remove-contact-btn cursor-pointer"
                        @click="removeName(index)"
                      >
                        <b-img
                          width="18"
                          src="/admin/images/table/cancel.svg"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-form-group>

            </app-collapse-item>

            <app-collapse-item
              title="進階設定"
              class="box-shadow-none"
            >
              <!-- 國家 -->
              <b-form-group
                label-for="country"
              >
                <template #label>
                  <label class="mb-0 pb-0">國家</label>
                </template>
                <!-- <vue-country-select
                  v-model="customerData.country"
                  :default-country="'TW'"
                  :disabled-fetching-country="true"
                  :enabled-flags="true"
                  :show-name-input="true"
                /> -->

                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <CountrySelect
                      @select-country="(item) => {
                        customerData.country = item.name
                      }"
                    />
                    <!-- input.value = item.iso2 -->
                  </b-input-group-prepend>

                  <b-form-input
                    v-model="customerData.country"
                    placeholder="請輸入國家"
                  />
                </b-input-group>
              </b-form-group>

              <!-- 地址 -->
              <b-form-group
                label-for="address"
              >
                <template #label>
                  <label class="mb-0 pb-0">地址</label>
                </template>
                <b-form-input
                  id="address"
                  ref="addressInput"
                  v-model="customerData.address"
                  trim
                  placeholder="請輸入地址"
                />
              </b-form-group>

              <!-- 備註 -->
              <b-form-group
                label-for="remark"
              >
                <template #label>
                  <label class="mb-0 pb-0">備註</label>
                </template>
                <b-form-input
                  id="remark"
                  ref="remarkInput"
                  v-model="customerData.remark"
                  trim
                  placeholder="請輸入備註"
                />
              </b-form-group>

              <!-- 聯絡方式 -->
              <b-form-group
                label-for="contact_method"
              >
                <label class="mb-0 pb-0 d-flex align-items-center">
                  聯絡方式
                  <!-- (新增)聯絡方式 -->
                  <div
                    class="add-contact-btn"
                    @click="addContact"
                  >
                    <b-img
                      width="18"
                      class=""
                      src="/admin/images/table/plus.svg"
                    />
                  </div>
                </label>
                <div class="py-1">
                  <b-row
                    v-for="contact, index in customerData.contact_method"
                    :key="index"
                    class="mb-1"
                  >
                    <b-col
                      cols="12"
                      sm="5"
                      class="mb-50 mb-md-0"
                    >
                      <b-form-input
                        v-model="contact.method"
                        placeholder="請輸入聯絡方式"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      sm="7"
                      class="d-flex align-items-center"
                    >
                      <b-form-input
                        v-model="contact.value"
                        class=""
                      />
                      <div
                        class="remove-contact-btn cursor-pointer ml-50"
                        @click="removeContact(index)"
                      >
                        <b-img
                          width="18"
                          src="/admin/images/table/cancel.svg"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-form-group>
            </app-collapse-item>

          </app-collapse>
        </b-form>
      </validation-observer>
    </div>

    <hr class="mt-0 dividing-line">

    <div class="text-right px-1">
      <b-button
        variant="outline-secondary"
        :disabled="isBusy"
        class="mr-1"
        @click="() => {
          resetCustomerTypeData()
          resetMetaList()
        }"
      >
        <span v-if="!isBusy">重設</span>

        <b-spinner
          v-else
          small
        />
      </b-button>

      <b-button
        variant="primary"
        :disabled="isBusy"
        @click="handleOk"
      >
        <span v-if="!isBusy">新增</span>

        <b-spinner
          v-else
          small
        />
      </b-button>
    </div>

    <admin-brand-explorer-modal
      ref="adminBrandExplorerAddModal"
      brand-explorer-id="adminBrandExplorerAddModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, VBToggle, VBTooltip, BImg, BButton, BSpinner,
  BInputGroup, BInputGroupPrepend, // BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import Ripple from 'vue-ripple-directive'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// Component
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
// import VueCountrySelect from 'vue-country-select-2'
import AdminBrandExplorerModal from '@/layouts/components/Modal/admin-brand-modal/brandModal.vue'
import CountrySelect from '@/layouts/components/CountrySelect.vue'
import {
  useCustomerList, useCustomerSetting, useCustomerView,
} from '../useCustomer'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
// import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BImg,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    BInputGroup,
    BInputGroupPrepend,
    // BFormTextarea,
    // BCollapse,

    vSelect,
    // VueCountrySelect,
    CountrySelect,
    // VuePerfectScrollbar,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AdminBrandExplorerModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    shallOpenCustomerAddSidebarState: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      showMoreSetting: false,
      // assignedUsers: [], => customerData.assigned_user
      assignedUser: {
        type: null,
        index: null,
      },
      configId: null,
      maxChar: 255,
    }
  },
  watch: {
    shallOpenCustomerAddSidebarState(newValue) {
      if (newValue) this.getData()
    },
  },
  methods: {
    // (計算)時間
    calculateDueDate(timeString) {
      const timeRegex = /^(\d+)([hd])$/
      const match = timeString.match(timeRegex)

      if (!match) return new Date()

      const value = parseInt(match[1], 10)
      const unit = match[2]

      const timeInterval = unit === 'h' ? value * 60 * 60 * 1000 : value * 24 * 60 * 60 * 1000

      const currentDate = new Date()
      const dueDate = new Date(currentDate.getTime() + timeInterval)

      return dueDate
    },

    // (增加)聯絡方式
    addContact() {
      const emptyContactMethod = { method: '', value: '' }
      if (this.customerData.contact_method) {
        this.customerData.contact_method.push(emptyContactMethod)
      } else {
        this.customerData.contact_method = [emptyContactMethod]
      }
    },

    removeContact(index) {
      this.customerData.contact_method.splice(index, 1)
    },

    // (增加)別名
    addName() {
      if (this.customerData.nickName) {
        this.customerData.nickName.push('')
      } else {
        this.customerData.nickName = ['']
      }
    },

    removeName(index) {
      this.customerData.nickName.splice(index, 1)
    },

    // 按下彈窗關閉
    handleHide() {
      // bvModalEvt.preventDefault()
      // this.resetCustomerTypeData()
      // this.isBusy = false
      // this.$nextTick(() => {
      //   this.$bvModal.hide('update-modal')
      // })
    },

    // 按下彈窗確認
    handleOk() {
      this.isBusy = true
      this.setCustomerCreate({
        ...this.customerData,
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$emit('refetch-data')
          this.resetCustomerTypeData()
          this.configId = null
          this.$nextTick(() => {
            this.$emit('close-sidebar', response.data.data)
          })
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // --------------------------------------------------------------------------------------------

    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const { departments, priority } = response.data.data
          this.departmentsOptions = departments
          this.priorityOptions = priority

          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          const customerStatus = response.data.data.customer_status
          this.customerStatusOptions = customerStatus

          const findAdminBrand = adminBrands.find(el => el.id === this.customerData.admin_brand_id)

          if (findAdminBrand === undefined) {
            this.customerData.admin_brand_id = null
          }
        })
    },

    // (獲取)資料
    getData() {
      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          // 資料同步
          // const resolveData = {
          //   ...this.syncObject(this.blankCustomerData, {}),
          // }

          // 判斷是否有在陣列中
          // const findDepartment = departments.find(el => el.id === this.customerDataItem.department_id)

          // if (findDepartment === undefined) {
          //   resolveData.department_id = null
          // } else {
          //   this.filterCustomerTypeOptions(resolveData.department_id)
          //   this.filterCustomerExecutionTypeOptions(resolveData.department_id)
          // }

          // this.customerData = resolveData
          // this.$bvModal.show('update-modal')
          // this.$refs.nameInput.focus()
        })
        .catch(() => {
          const resolveData = {
            ...this.syncObject(this.blankCustomerData, {}),
          }
          this.customerData = resolveData
          // this.$refs.nameInput.focus()
          // this.$bvModal.show('update-modal')
        })
    },
  },
  setup() {
    localize('tw')

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      isBusy,
      setCustomerCreate,
    } = useCustomerList()

    const {
      blankCustomerData,
    } = useCustomerView()

    const {
      blankSimpleInfo,
      roleLists,
      roleOptions,
      getMetaListData,
      adminBrandOptions,
      syncObject,
    } = useCustomerSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const formatDate = date => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}`
    }

    const customerData = ref(JSON.parse(JSON.stringify(blankCustomerData)))

    const resetCustomerTypeData = () => {
      customerData.value = JSON.parse(JSON.stringify(blankCustomerData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCustomerTypeData)

    const dateConfig = {
      locale: language.zh_tw,
      dateFormat: 'Y-m-d H:i',
      allowInput: true,
      static: true,
      enableTime: true,
      // inline: true,
    }

    return {
      perfectScrollbarSettings,

      isBusy,
      dateConfig,

      customerData,
      blankSimpleInfo,
      blankCustomerData,
      roleLists,
      roleOptions,
      adminBrandOptions,
      setCustomerCreate,
      getMetaListData,

      resetCustomerTypeData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
      formatDate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
// .profile-sidebar-area {
//   height: 90% !important;
// }
.add-contact-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  // border-radius: 50%;
  // border: 2px dashed #48c8ef;
  cursor: pointer;
}
.show-more-arrow {
  transition: transform 0.3s ease-in-out;
  .active {
    transition: transform 0.3s ease-in-out;
    transform: rotate(90deg);
  }
  // .show {
  //   transform: translateX(100%);
  // }
  // span {
  //   display: inline-block;
  //   transition: transform 1s ease-in-out;
  // }
}
.badge-btn:hover {
  background-color: rgba(117, 174, 227, 0.363);
}

.scroll-area {
  height: calc(100% - 130px);
  overflow-y: scroll;
}

.dividing-line {
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.5);
}

.image-icon {
  height: 16px;
}

.country-select {
  background-color: red;
}

</style>
