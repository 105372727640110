<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <!-- d-flex align-items-center -->
    <!-- py-75 px-1 -->

    <slot name="contextmenu" />
    <slot name="checkbox" />
    <slot
      name="mask"
      class="chat-contact-mask"
    />

    <div @click="$emit('submit-show-content', customerData.id)">
      <b-avatar
        v-if="customerData.image"
        size="42"
        class="badge-minimal border mr-75"
        :src="`${customerData.image}/sm`"
        variant="light-secondary"
      />
      <b-avatar
        v-else
        size="42"
        class="badge-minimal border mr-75"
        :text="avatarText(customerData.name)"
        variant="light-secondary"
      >
        <!-- <template #badge>
          <span
            class="bullet filter-dot"
            :style="{'--text-color':`${customerData.priority_info.color}`}"
          />
        </template> -->
      </b-avatar>
    </div>

    <div class="d-flex align-self-start w-100 justify-content-between">
      <div
        class="chat-info flex-grow-1 pl-0"
        @click="$emit('submit-show-content', customerData.id)"
      >
        <div
          class="text-truncate"
          :class="{'text-primary': isChatContact }"
        >
          <small v-if="customerData.admin_brand">
            {{ customerData.admin_brand.name }}
          </small>
          <small
            v-else
          >
            尚未設定
          </small>
        </div>

        <h5
          class="text-truncate mb-0"
          :class="!customerData.client_name && !customerData.department_id && !customerData.created_user_id && customerData.display_status ? 'mb-75' : 'mb-0'"
        >
          #{{ customerData.id }} {{ customerData.name }}
        </h5>

      </div>

      <!-- 已讀/未讀 -->
      <div class="chat-meta text-nowrap">
        <small class="float-right my-25 chat-time">
          <span v-if="customerData.review_user_id">
            <b-img
              src="/admin/images/todo/action/done.svg"
              width="14"
              height="14"
              style="border: none"
            />
          </span>
          <span v-if="customerData.updated_at">
            <span
              v-b-tooltip.hover.focus.v-secondary
              :title="`${moment(customerData.updated_at).format('HH:mm')}`"
            >
              {{ updateOnline(customerData.updated_at, refonlineTime) }}
            </span>
          </span>
        </small>

        <div>
          <small
            class="float-right mr-25"
          >
            <span v-if="calcRemainDays(customerData.deadline_at) <= 1 && updateExtendedOnline(customerData.deadline_at)">
              <b-img
                src="/admin/images/todo/action/alarm-clock.svg"
                width="16"
                height="16"
                style="border: none"
                class="alarm-clock"
              />
            </span>
          </small>
        </div>

        <!-- <div class="mt-3">
          <small class="float-right mr-25 mt-25">
            1234
          </small>
        </div> -->
      </div>
    </div>

    <!-- {{ customerData }} -->
  </component>
</template>

<script>
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import {
  BBadge, VBTooltip, BImg, BAvatar,
} from 'bootstrap-vue'
import { useCustomerSetting } from '../useCustomer'

export default {
  components: {
    BImg,
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    customerData: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (處理時間)
    resolveTime(time) {
      if (time) return moment(time).format('YYYY/MM/DD HH:mm')
      return moment().format('YYYY/MM/DD HH:mm')
    },

    // (計算)剩餘天數
    calcRemainDays(end) {
      const startDate = moment(new Date())
      let daysDiff = 0
      const endDate = moment(end)
      daysDiff = endDate.diff(startDate, 'days')
      return daysDiff
    },

    updateExtendedOnline(time, skip) {
      const endTime = Math.floor(new Date(this.resolveTime(time).replace(/-/g, '/')).getTime() / 1000)
      const today = Math.floor(new Date().getTime() / 1000)
      let diffTime = endTime - today
      if (diffTime <= 0) return false
      if (this.customerData.status === 'completed') return false
      if (this.customerData.review_user_id) return false

      // 定義單位時間
      const second = 1
      const minute = second * 60
      const hour = minute * 60
      const day = hour * 24

      const days = Math.floor(diffTime / day)
      diffTime -= days * 24 * 60 * 60

      const hours = Math.floor(diffTime / hour)
      diffTime -= hours * 60 * 60

      const minutes = Math.floor(diffTime / minute)
      diffTime -= minutes * 60

      if (!skip) if (hours >= 6) return false

      if (days > 0) return `${days} 天 ${hours} 小時`
      if (hours > 0) return `${hours} 小時 ${minutes} 分`
      return `${minutes} 分 ${diffTime} 秒`
    },
  },
  setup() {
    const {
      // ui,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useCustomerSetting()

    return {
      refonlineTime,
      onlineTime,
      updateOnline,
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.badge-minimal {
  position: relative;
}

.border-deadline {
  animation: breathe 1.5s infinite;
  @keyframes breathe {
    0% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0);
    }
    25% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0.4);
    }
    50% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0.8);
    }
    75% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0.4);
    }
    100% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0);
    }
  }
}

.filter-dot {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 9px;
  width: 9px;
  z-index: 10;
  background-color: var(--text-color);
  // box-shadow: 0 0 5px 2px var(--text-color);
  // border-radius: 50%;
  // padding: 2px;
  // margin-right: 5px;
}

.customer-status-icon {
  height: 14px;
  width: 14px;
  border: none !important;
  margin-top: -2px;
}

.text-truncate {
  max-width: 180px;
}

.alarm-clock {
  animation: shake 1s ease-in-out infinite; /* 持續時間 */
}

@keyframes shake {
  0%, 100% { transform: translate(0, 0) rotate(0); }
  10% { transform: translate(-1px, 0) rotate(-1deg); }
  10% { transform: translate(1px, 0) rotate(1deg); }
  10% { transform: translate(-1px, 0) rotate(-1deg); }
  40% { transform: translate(1px, 0) rotate(1deg); }
  50% { transform: translate(-1px, 0) rotate(-1deg); }
  60% { transform: translate(1px, 0) rotate(1deg); }
  70% { transform: translate(-1px, 0) rotate(-1deg); }
  80% { transform: translate(1px, 0) rotate(1deg); }
  90% { transform: translate(-1px, 0) rotate(-1deg); }
}

.chat-contact-mask {
  // background-color: rgba(108, 196, 255, 0.1);
  position: absolute;
  z-index: 20;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (max-width: 400px) {
  .text-truncate {
    max-width: 100px;
  }
}
</style>
