<template>
  <div>
    <!-- 上傳 -->
    <!-- <div ref="uploadCardContent">
      <div
        ref="uploadCard"
        no-body
        class="image-explorer-contain upload-card"
        @dragenter="dragEnter"
        @dragover="preventDefault"
        @dragleave="dragLeave"
        @drop="dropFile"
      >
        <div
          ref="dropArea"
          class="dropArea hidden"
        >
          <div class="upload-label">
            <span class="label-title">
              把檔案拖放到這裡
            </span>
            <b-img
              :src="require('@/assets/images/icons/image-processing.png')"
              fluid
            />
          </div>
        </div>
      </div>
    </div> -->

    <b-modal
      id="quickBaseModal"
      no-close-on-backdrop
      centered
      header-bg-variant="info"
      body-class="p-0"
      size="md"
      @hidden="handleHideSiteModal"
      @close="handleHideSiteModal"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          快速新增
        </h4>
      </template>

      <div>
        <b-row>
          <b-col
            cols="12"
          >
            <app-collapse
              accordion
              class="mb-1 box-shadow-none"
            >
              <!-- 基本設定 -->
              <app-collapse-item
                title="基本設定"
                is-visible
                class="box-shadow-none"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <!-- 客戶姓名 -->
                    <b-form-group
                      label-for="name"
                    >
                      <template #label>
                        <div
                          class="d-flex justify-content-between align-items-center"
                          style="height: 24px;"
                        >
                          <label class="mb-0 pb-0">
                            客戶名稱
                            <span class="text-danger">*</span>
                          </label>
                        </div>
                      </template>
                      <b-form-input
                        id="name"
                        ref="nameInput"
                        v-model="customerData.name"
                        trim
                        placeholder="請輸入客戶名稱"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <!-- 負責品牌 -->
                    <b-form-group label-for="admin_brand_id">
                      <template #label>
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="mb-0">負責品牌</label>

                          <span>
                            <div
                              v-b-tooltip.hover.v-secondary
                              title="公司品牌設定"
                              class="d-flex align-items-center actions-link-btn-sm"
                              @click="() => { $refs.adminBrandExplorerBaseModal.getData(null) }"
                            >
                              <b-img
                                src="/admin/images/table/settings.svg"
                                class="actions-link-btn-image"
                                rounded
                              />
                            </div>
                          </span>
                        </div>
                      </template>

                      <v-select
                        v-model="customerData.admin_brand_id"
                        :options="adminBrandOptions"
                        :clearable="false"
                        label="name"
                        :reduce="option => option.id"
                        placeholder="請選擇品牌"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            沒有<em>{{ search }}</em> 的相關結果
                          </template>

                          <div v-else>
                            <small>暫無資料</small>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="12"
                  >
                    <!-- 電子信箱 -->
                    <b-form-group
                      label-for="email"
                    >
                      <div class="d-flex align-items-center">
                        <label class="mb-0 pb-0">
                          電子信箱
                          <span class="text-danger">*</span>
                        </label>
                      </div>
                      <b-form-input
                        id="email"
                        ref="emailInput"
                        v-model="customerData.email"
                        trim
                        type="email"
                        placeholder="請輸入電子信箱"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>

              <!-- 進階設定 -->
              <app-collapse-item
                title="進階設定"
                class="box-shadow-none"
              >
                <b-row>
                  <b-col cols="12">
                    <!-- 國家 -->
                    <b-form-group label-for="country">
                      <div class="d-flex align-items-center">
                        <label class="mb-25 pb-0">國家</label>
                      </div>
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <CountrySelect
                            @select-country="(item) => {
                              customerData.country = item.name
                            }"
                          />
                          <!-- input.value = item.iso2 -->
                        </b-input-group-prepend>

                        <b-form-input
                          v-model="customerData.country"
                          placeholder="請輸入國家"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <!-- 地址 -->
                    <b-form-group
                      label-for="address"
                    >
                      <template #label>
                        <label class="mb-0 pb-0">地址</label>
                      </template>
                      <b-form-input
                        id="address"
                        ref="addressInput"
                        v-model="customerData.address"
                        trim
                        placeholder="請輸入地址"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- 聯絡方式 -->
                <b-form-group
                  label-for="contact_method"
                >
                  <label class="mb-0 pb-0 d-flex align-items-center">
                    聯絡方式
                    <!-- (新增)聯絡方式 -->
                    <div
                      class="add-contact-btn"
                      @click="addContact"
                    >
                      <b-img
                        width="18"
                        class=""
                        src="/admin/images/table/plus.svg"
                      />
                    </div>
                  </label>
                  <div class="py-1">
                    <b-row
                      v-for="contact, index in customerData.contact_method"
                      :key="index"
                      class="mb-1"
                    >
                      <b-col
                        cols="6"
                        md="5"
                        class="mb-50 mb-md-0"
                      >
                        <b-form-input
                          v-model="contact.method"
                          class=""
                          placeholder="請輸入聯絡方式"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="7"
                      >
                        <b-form-input
                          v-model="contact.value"
                          class=""
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
              </app-collapse-item>

            </app-collapse>
          </b-col>
        </b-row>

        <!-- 備註 -->
        <div class="px-1 mb-1">
          <label>備註</label>

          <div class="textarea-section">
            <b-form-input
              ref="resizeTextarea"
              v-model="customerData.remark"
              class="w-100"
              autofocus
              :style="{ 'color': !isDark ? '#000' : '#d0d2d6'}"
              @keydown.enter.prevent="handleConfirmSiteModal"
            />
          </div>
        </div>

      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            :disabled="isRemarkBusy"
            @click="handleHideSiteModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isRemarkBusy"
            @click="handleConfirmSiteModal"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>

    <admin-brand-explorer-modal
      ref="adminBrandExplorerBaseModal"
      brand-explorer-id="adminBrandExplorerBaseModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { ref, computed } from '@vue/composition-api'
import {
  BImg, BModal, BButton, BRow, BCol, VBTooltip, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// import VueCountrySelect from 'vue-country-select-2'
import CountrySelect from '@/layouts/components/CountrySelect.vue'
import {
  useCustomerSetting, useRemarkList, useCustomerList, useCustomerView,
} from '../useCustomer'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
import AdminBrandExplorerModal from '@/layouts/components/Modal/admin-brand-modal/brandModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    AppCollapse,
    AppCollapseItem,

    vSelect,
    // VueCountrySelect,
    CountrySelect,
    AdminBrandExplorerModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
  },
  data() {
    return {
      configId: null,
      maxChar: 255,
    }
  },
  methods: {
    moment,
    // (增加)聯絡方式
    addContact() {
      const emptyContactMethod = { method: '', value: '' }
      if (this.customerData.contact_method) {
        this.customerData.contact_method.push(emptyContactMethod)
      } else {
        this.customerData.contact_method = [emptyContactMethod]
      }
    },

    // ------------------------------------編輯快速編輯-----------------------------------------
    // (顯示)彈窗
    handleShowSiteModal() {
      this.customerData = JSON.parse(JSON.stringify(this.blankCustomerData))
      this.$bvModal.show('quickBaseModal')
      // this.$refs.dropArea.classList.add('hidden')
      this.resetMetaList()

      setTimeout(() => {
        this.$refs.resizeTextarea.focus()
      }, 100)
    },

    // (關閉)彈窗
    handleHideSiteModal() {
      if (this.isRemarkBusy) return
      this.$bvModal.hide('quickBaseModal')
    },

    // (確認)彈窗
    async handleConfirmSiteModal() {
      if (this.isRemarkBusy) return
      this.isRemarkBusy = true
      this.setCustomerCreate({
        ...this.customerData,
      })
        .then(() => {
          this.$emit('refresh-customer-list')
          this.isRemarkBusy = false
          this.handleHideSiteModal()
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
          this.isRemarkBusy = false
        })
    },

    // ------------------------------------------------------------------------------------------------
    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          // 判斷是否有在陣列中
          const findAdminBrand = adminBrands.find(el => el.id === this.customerData.admin_brand_id)

          if (findAdminBrand === undefined) {
            this.customerData.admin_brand_id = null
          }
        })
    },
  },
  setup() {
    moment.locale('zh-tw')
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      syncObject,
      blankSimpleInfo,
      getMetaListData,
      adminBrandOptions,
      typeOptions,
    } = useCustomerSetting()

    const {
      setCustomerCreate,
    } = useCustomerList()

    const {
      blankCustomerData,
    } = useCustomerView()

    const {
      isRemarkBusy,
      blankImageData,
      judgeTypeOption,
      resolveRemarkInfoData,
    } = useRemarkList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const configId = ref(null)
    const customerData = ref(JSON.parse(JSON.stringify(blankCustomerData)))

    return {
      isDark,
      configId,
      blankSimpleInfo,
      customerData,
      blankCustomerData,
      getMetaListData,
      adminBrandOptions,
      typeOptions,
      syncObject,

      judgeTypeOption,
      isRemarkBusy,
      blankImageData,

      resolveRemarkInfoData,

      useAlertToast,
      useHttpCodeAlert,
      setCustomerCreate,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-context.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.upload-card {
  // background-color: rgb(40, 240, 63);
  position: relative;
  min-height: 70vh;
  .dropArea {
    min-height: 50%;
    position: absolute;
    z-index: 1000;
    padding: 30px;
    top: 0; // 60%
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #282828;
    min-height: 90vh;
    opacity: .9;
    // backdrop-filter: blur(10px);
    text-align: center;
    align-self: center;
    color: #fff;
    // text-shadow: 0em 0.01em #333, 0em 0.02em #333, 0em 0.02em 0.03em #333;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 40vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 2rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
  }

  .upload-label-text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 900;
    padding: 1rem 0 0.5rem;
  }
}

.add-contact-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  // border-radius: 50%;
  // border: 2px dashed #48c8ef;
  cursor: pointer;
}

.textarea-section {
  position: relative;
  .emoji-area {
    position: absolute;
    bottom: 0;
    right: 8px;
    margin-top: 20px;
  }
}

.show-more-arrow {
  transition: transform 0.3s ease-in-out;
  .active {
    transition: transform 0.3s ease-in-out;
    transform: rotate(90deg);
  }
}

.model-header {
  color: white;
  padding: 3px 0;
}

.image-icon {
  height: 16px;
}

</style>
