<template>
  <div
    class="todo-sidebar"
    :class="{'show': mqShallShowLeftSidebar}"
  >
    <!-- 按鈕列 -->
    <div class="sidebar-search">

      <div class="sidebar-search-all d-flex align-items-center justify-content-center">
        <!-- 圖表 -->
        <div
          class="sidebar-search-all d-flex align-items-center justify-content-center cursor-pointer search-icon-div"
          @click="$emit('change-active-chart-show')"
        >
          <!-- @click="$bvModal.show('sidebarFilter')" -->
          <b-avatar
            id="customer-search-chart"
            size="40"
            class="badge-minimal p-25 border search-icon"
            :variant="shallCustomerChartShowState ? 'info' : 'light-primary'"
            src="/admin/images/todo/action/ranking.png"
          />

          <b-tooltip
            target="customer-search-chart"
            boundary-padding="10"
            boundary="window"
            placement="right"
          >
            統計資料
          </b-tooltip>
        </div>
      </div>

      <hr
        class="mt-0 mb-25"
        :style="{ 'border-color': '#a4a9b080'}"
      >

      <div class="sidebar-search-area">

        <div class="sidebar-search-content">
          <div class="mt-25" />

          <!-- 篩選器 -->
          <div
            class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
            @click="$bvModal.show('sidebarFilter')"
          >
            <b-avatar
              id="customer-search-filter"
              size="40"
              class="badge-minimal p-25 border search-icon"
              :variant="isFilterUse ? 'info' : 'light-primary'"
              src="/admin/images/todo/action/filters.svg"
            />

            <b-tooltip
              target="customer-search-filter"
              boundary-padding="10"
              boundary="window"
              placement="right"
            >
              篩選器
            </b-tooltip>
          </div>

          <!-- 創建日期 -->
          <div
            class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
            @click="openCreatedModel"
          >
            <b-avatar
              id="customer-search-created"
              size="40"
              class="badge-minimal p-25 border search-icon"
              :variant="timeRange ? 'info' : 'light-primary'"
              src="/admin/images/todo/action/schedule.svg"
            />

            <b-tooltip
              target="customer-search-created"
              boundary-padding="10"
              boundary="window"
              placement="right"
            >
              創建日期
            </b-tooltip>
          </div>

          <!-- 公司品牌 -->
          <div class="animate__animated animate__fadeIn">
            <div
              v-for="adminBrand in $store.state['admin-customer'].adminBrand"
              :key="adminBrand.id"
              class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
              @click="clickFilterBrandBtn(adminBrand.id)"
            >
              <b-avatar
                :id="adminBrand.name"
                size="40"
                class="badge-minimal p-25 border search-icon"
                :variant="isFilterAdminBrandUse(adminBrand.id) ? 'info' : 'light-primary'"
                :text="avatarText(adminBrand.name)"
              />

              <b-tooltip
                :target="adminBrand.name"
                boundary-padding="10"
                boundary="window"
                placement="right"
              >
                {{ adminBrand.name }}
              </b-tooltip>
            </div>
          </div>

        </div>

        <div class="sidebar-search-all d-flex align-items-center justify-content-center flex-column sidebar-search-footer">
          <!-- 滿版模式 -->
          <div
            id="customer-full-screen"
            class="d-flex align-items-center icon-expand-link-btn cursor-pointer"
            @click="submitUpdateFullScreen"
          >
            <!-- $store.commit('app/UPDATE_CUSTOMER_FULLSCREEN') -->
            <b-img
              :src="`/admin/images/todo/action/${$store.state.app.customerFullScreen ? 'minimise' : 'expand'}.svg`"
              class="icon-expand-link-btn-image"
              rounded
            />
          </div>

          <b-tooltip
            target="customer-full-screen"
            boundary-padding="10"
            boundary="window"
            placement="right"
          >
            {{ $store.state.app.customerFullScreen ? '縮小' : '全屏顯示' }}
          </b-tooltip>
        </div>
      </div>

    </div>

    <!-- 列表 -->
    <div class="sidebar-left">
      <div class="sidebar">
        <!-- 新增客戶 -->
        <customer-add-sidebar
          :shall-open-customer-add-sidebar-state="shallOpenCustomerAddSidebarState"
          @refetch-data="refetchData"
          @close-sidebar="customer => {
            $emit('update:shall-open-customer-add-sidebar-state', false)
            if (customer) $emit('open-chat', customer.id)
          }"
        />

        <!-- 個人設定 -->
        <!-- <customer-setting-sidebar
          :shall-open-customer-setting-sidebar-state="shallOpenCustomerSettingSidebarState"
          @open-customer-config-list-data="$refs.customerSidebarConfig.handleShowListModal()"
          @refetch-data="refetchData"
          @close-sidebar="$emit('update:shall-open-customer-setting-sidebar-state', false)"
        /> -->

        <div
          class="sidebar-content"
        >
          <!-- :class="{'show': mqShallShowLeftSidebar}" -->

          <span class="sidebar-close-icon">
            <feather-icon
              icon="XIcon"
              size="16"
              @click="$emit('update:mq-shall-show-left-sidebar', false)"
            />
          </span>

          <!-- Header -->
          <div class="chat-fixed-search">
            <div
              v-if="selectedList.length <= 0"
              class="d-flex align-items-center w-100"
            >
              <!-- Search -->
              <b-input-group class="input-group-merge w-100 round">
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="搜尋..."
                  debounce="500"
                />
              </b-input-group>

              <div class="sidebar-profile-toggle ml-50 d-flex">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="$emit('open-customer-add-sidebar')"
                >
                  <b-img
                    src="/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="排序"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="{ 'rotated': isSortDirDesc }"
                  @click="isSortDirDesc = !isSortDirDesc"
                >
                  <b-img
                    src="/admin/images/todo/action/arrows.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

              </div>
            </div>

            <div
              v-if="selectedList.length > 0"
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <small>已選擇 {{ selectedList.length }} 位客戶</small>
              </div>

              <div class="d-flex text-nowrap">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  class="d-flex align-items-center actions-link-btn"
                  @click="submitDeleteCustomer(selectedList)"
                >
                  <b-img
                    src="/admin/images/todo/action/trash.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="取消"
                  class="d-flex align-items-center actions-link-btn"
                  @click="() => { selectedList = [] }"
                >
                  <b-img
                    src="/admin/images/table/cancel.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- ScrollArea: Chat & Contacts -->
          <vue-perfect-scrollbar
            ref="customerListContent"
            :settings="perfectScrollbarSettings"
            class="chat-user-list-wrapper list-group scroll-area"
          >

            <div class="animate__animated animate__fadeIn">
              <ul
                v-if="customerData.length > 0"
                class="chat-users-list chat-list media-list"
              >
                <customer-contact
                  v-for="customer, index in customerData"
                  :id="`customer-contact-${index}`"
                  :key="customer.id"
                  ref="customerContact"
                  v-contextmenu:contextmenu
                  :customer-data="customer"
                  tag="li"
                  :class="{'active': activeChatContactId === customer.id}"
                  class="chat-contact show-popup"
                  :is-chat-contact="activeChatContactId !== customer.id"
                  @submit-show-content="id => {
                    showPopup = false
                    targetRouteContent(customer.id)
                    submitShowContent(id, customer)
                  }"
                  @contextmenu.prevent="() => { rightClick = customer }"
                >
                  <div
                    v-if="selectedList.length > 0"
                    slot="checkbox"
                  >
                    <b-form-checkbox
                      v-model="selectedList"
                      :value="customer.id"
                      name="muti-selected"
                    />
                  </div>

                  <div
                    v-if="selectedList.length > 0"
                    slot="mask"
                    class="chat-contact-mask"
                    @click="submitSelectedCustomer(customer.id)"
                  />
                </customer-contact>
              </ul>

              <div
                v-if="isBusyLoading || isBusy"
                class="text-center p-2"
              >
                <b-img
                  src="/admin/images/common/loading-2.png"
                  rounded
                  height="30"
                  width="30"
                />
              </div>

              <!-- {{ selectedList }} -->

              <!-- <div
                v-if="!isBusyLoading && !isAll && customerData.length !== 0"
                class="text-center p-2"
              >
                <span>沒有更多資料了</span>
              </div> -->
            </div>

            <!-- {{ rightClick }} -->

            <!-- <div
              v-else
              class="text-center p-5"
            >
              <b-img
                src="/admin/images/common/loading-2.png"
                rounded
                height="60"
                width="60"
              />
            </div> -->
          </vue-perfect-scrollbar>

          <div
            v-show="showPopup"
            ref="customerPopup"
            class="popup animate__animated animate__fadeIn animate__faster border my-1"
          >
            <div
              v-if="customerPopup"
              class="header"
            >
              <div class="banner" />
              <div class="top-section">
                <div class="profile">
                  <b-avatar
                    size="54"
                    class="badge-minimal border"
                    :src="`${customerPopup.image}/sm`"
                    :text="avatarText(customerPopup.name)"
                    variant="light-primary"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="customerPopup"
              class="body"
            >
              <div class="info-card">
                <div class="d-flex flex-column mb-50">
                  <h4>
                    {{ customerPopup.name }}
                  </h4>
                  <h5>
                    {{ customerPopup.email }}
                  </h5>
                </div>
                <b-tabs>
                  <b-tab
                    title="使用者資訊"
                    active
                  >
                    <!-- 創建時間 -->
                    <div class="mb-25">
                      <label
                        v-if="customerPopup.admin_brand"
                        class="font-weight-bolder"
                      >
                        成為 {{ customerPopup.admin_brand.name }} 客戶時間
                      </label>
                      <label
                        v-else
                        class="font-weight-bolder"
                      >
                        創建時間
                      </label>
                      <div class="">
                        <div v-if="customerPopup.created_at">
                          <span
                            v-b-tooltip.hover.focus.v-secondary
                            :title="`${moment(customerPopup.created_at).format('HH:mm')}`"
                          >
                            {{ moment(customerPopup.created_at).format('YYYY-MM-DD') }}
                          </span>
                        </div>

                        <div v-else>
                          <span>---</span>
                        </div>
                      </div>
                    </div>
                    <!-- 創建人 -->
                    <div class="mb-25">
                      <label class="font-weight-bolder">
                        創建人
                      </label>
                      <div class="">
                        <div>
                          <span v-if="customerPopup.user">
                            {{ customerPopup.user.name }}
                          </span>
                          <span v-else>---</span>
                        </div>
                      </div>
                    </div>
                    <!-- 備註 -->
                    <div class="mb-25">
                      <label class="font-weight-bolder">
                        備註
                      </label>
                      <div>
                        <span v-if="customerPopup.remark">
                          {{ customerPopup.remark }}
                        </span>
                        <span v-else>
                          ---
                        </span>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab
                    title="最新待辦事項"
                  >
                    <div
                      v-if="customerPopup.last_todo"
                      v-b-tooltip.hover.focus
                      title="點擊查看"
                      class="last-todo-card"
                      variant="secondary"
                      @click="targetRouteTodo(customerPopup.last_todo.id)"
                    >
                      <div
                        class="card-info"
                      >
                        <div class="d-flex align-items-center">
                          <b-avatar
                            size="50"
                            :src="`/admin/images/todo/${customerPopup.last_todo.status}.svg`"
                            class="border badge-minimal mr-1"
                            badge
                            variant="light-secondary"
                          >
                            <template #badge>
                              <span
                                class="bullet filter-dot"
                                :style="{'--text-color':`${customerPopup.last_todo.priority_info.color}`}"
                              />
                            </template>
                          </b-avatar>
                          <div class="d-flex flex-column">
                            <small
                              v-if="customerPopup.last_todo.department_info"
                              class="mb-0 text-primary todo-text"
                            >
                              {{ customerPopup.last_todo.department_info.name }}
                            </small>
                            <small
                              v-else
                            >
                              尚未設定
                            </small>
                            <h3
                              class="mb-0 todo-text"
                              :class="{ 'text-muted': !customerPopup.last_todo.title }"
                            >{{ customerPopup.last_todo.title ? customerPopup.last_todo.title : '未命名標題' }}</h3>
                            <h5 class="mb-0 align-self-end text-right todo-text">
                              #{{ customerPopup.last_todo.serialNumber }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <h5 class="mb-50 cursor-pointer todo-text text-muted">
                        暫無待辦事項
                      </h5>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>

          <v-contextmenu
            ref="contextmenu"
            :class="$store.state.appConfig.layout.skin === 'dark' ? 'my-contextmenu-dark' : 'my-contextmenu'"
          >
            <div>
              <div class="context-menu-title mb-50">
                {{ rightClick.name }}
              </div>

              <v-contextmenu-item divider />

              <v-contextmenu-group v-if="selectedList.length <= 0">
                <v-contextmenu-item @click="openUpdateSidebar">
                  <div
                    class="context-menu-btn"
                  >
                    <b-img
                      src="/admin/images/table/edit.svg"
                      class="context-menu-btn-image"
                      rounded
                    />
                  </div>
                </v-contextmenu-item>

                <v-contextmenu-item @click="submitDeleteCustomer([rightClick.id])">
                  <!-- v-b-tooltip.hover.focus.v-secondary
                  title="刪除" -->
                  <div class="context-menu-btn">
                    <b-img
                      src="/admin/images/todo/action/trash.svg"
                      class="context-menu-btn-image"
                      rounded
                    />
                  </div>
                </v-contextmenu-item>
              </v-contextmenu-group>

              <!-- <v-contextmenu-item :auto-hide="false">
                {{ $store.state.appConfig.layout.skin }}
              </v-contextmenu-item> -->
              <v-contextmenu-item
                v-if="selectedList.length <= 0"
                divider
              />

              <v-contextmenu-item
                class="dropdown-item-area"
                @click="submitShareCustomer"
              >
                <b-img
                  src="/admin/images/todo/action/share.svg"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>分享連結</span>
              </v-contextmenu-item>

              <v-contextmenu-item
                class="dropdown-item-area"
                @click="submitSelectedCustomer(rightClick.id)"
              >
                <!-- @click="submitSelectedCustomer(rightClick.id)" -->
                <b-img
                  src="/admin/images/todo/action/check1.svg"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>{{ isIndexInSelectedCustomer(rightClick.id) ? '取消選取' : '選取客戶' }}</span>
              </v-contextmenu-item>
            </div>
          </v-contextmenu>
        </div>

      </div>

      <!-- 篩選器 -->
      <b-modal
        id="sidebarFilter"
        centered
        hide-footer
        size="md"
        header-bg-variant="primary"
        no-close-on-backdrop
      >
        <!-- no-close-on-backdrop -->
        <template #modal-title>
          <h4 class="m-0 model-header">
            篩選器
          </h4>
        </template>

        <div class="mb-2">
          <b-row>
            <b-col
              cols="12"
            >
              <!-- 排序內容 -->
              <b-form-group>
                <label>排序內容</label>
                <v-select
                  v-model="sortBy"
                  :options="searchOptions"
                  :reduce="option => option.value"
                  :clearable="false"
                  placeholder="請選擇排序內容"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 排序方式 -->
              <b-form-group>
                <label>排序方式</label>

                <v-select
                  v-model="isSortDirDesc"
                  :options="[{ label: '降序排列', value: true }, { label: '升序排列', value: false }]"
                  :reduce="option => option.value"
                  :clearable="false"
                  placeholder="排序方式"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 負責品牌 -->
              <b-form-group>
                <label>負責品牌</label>

                <v-select
                  v-model="searchAdminBrand"
                  :options="adminBrandOptions"
                  label="name"
                  multiple
                  :reduce="option => option.id"
                  placeholder="請選擇品牌"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

      </b-modal>

      <!-- 創建日期 -->
      <b-modal
        id="sidebarFilterCreated"
        centered
        hide-footer
        header-bg-variant="info"
      >
        <!-- no-close-on-backdrop -->
        <template #modal-title>
          <h4 class="m-0 model-header">
            創建日期
          </h4>
        </template>

        <div class="mb-1">
          <date-range-customize
            ref="dateRangeCustomizeCreated"
            date-range-customize-id="dateRangeCustomizeCreated"
            :use-time="true"
            :init-data="timeRange"
            :max-date-time="moment().format('YYYY-MM-DD HH:mm')"
            @call-back-time="(time) => timeRange = time"
          >
            <template slot="quick-time-btn">
              <div class="mb-50">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(0, 'd')"
                >
                  今天
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-1, 'd')"
                >
                  昨天
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-7, 'd')"
                >
                  上周
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-14, 'd')"
                >
                  上兩周
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="getThisMonthRange"
                >
                  本月
                </b-button>
              </div>
            </template>
          </date-range-customize>
          <!-- @call-back-time="(time) => timeRange = time" -->
        </div>

      </b-modal>

    </div>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import {
  BInputGroup, BInputGroupPrepend, BFormInput, VBTooltip, BImg, BFormCheckbox, BModal, BFormGroup, BAvatar,
  BTooltip, BButton, BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import CustomerContact from './CustomerContact.vue'
import CustomerAddSidebar from './CustomerAddSidebar.vue'
// import CustomerSettingSidebar from './CustomerSettingSidebar.vue'
import { useCustomerList, useCustomerSetting } from '../useCustomer'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
// import DateTimeRangeCustomize from '@/layouts/components/Time/DateTimeRangeCustomize.vue'
import DateRangeCustomize from '@/layouts/components/Time/DateRangeCustomize.vue'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BImg,
    BModal,
    BAvatar,
    // BBadge,
    BTooltip,
    BButton,
    BFormGroup,
    // BDropdown,
    // BDropdownForm,
    BFormCheckbox,
    // BButton,
    BInputGroup,
    // BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    vSelect,
    CustomerContact,
    CustomerAddSidebar,
    // CustomerSettingSidebar,
    // DateRangeCustomize,
    DateRangeCustomize,
    // DateTimeRangeCustomize,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    shallCustomerChartShowState: {
      type: Boolean,
      required: true,
    },
    shallOpenCustomerAddSidebarState: {
      type: Boolean,
      required: true,
    },
    shallOpenCustomerSettingSidebarState: {
      type: Boolean,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rightClick: {},
      rightClickSearch: {},
      rightClickSearchIndex: null,

      hoverEl: null,
      showPopup: false,
      customerPopup: null,
      openTimer: null,
      closeTimer: null,
      openBusy: false,
      closeBusy: false,

      isLoading: false, // true: disable
      dateRangeText: null, // 時間區間 2023-01-01 to 2023-01-02
    }
  },
  computed: {
    isAll() {
      const num = this.currentPage * this.perPage
      if (num > this.totalNum) return false
      return true
    },
    isFilterUse() {
      if (this.searchAdminBrand !== null && this.searchAdminBrand.length > 0) {
        return true
      }
      // if (this.searchDisplay) {
      //   return true
      // }

      if (this.searchDepartment) {
        return true
      }

      if (this.searchExecutionType) {
        return true
      }

      if (this.searchType) {
        return true
      }

      return false
    },
  },
  watch: {
    customerData: {
      handler(newValue) {
        this.showPopup = false
        this.selectedList = this.selectedList.filter(selectedItem => {
          const matchingItem = newValue.find(customerItem => customerItem.id === selectedItem)
          return matchingItem !== undefined
        })
      },
      deep: true,
    },

    // customSearch: {
    //   handler(newValue) {
    //     localStorage.setItem('customerFullScreen', JSON.stringify(newValue.useFullScreen))
    //     this.$store.commit('app/UPDATE_CUSTOMER_FULLSCREEN', newValue.useFullScreen)
    //     this.$store.commit('admin-customer/UPDATE_USE_AUDIO_STATE', newValue.useAudio)
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    const customerListContentElement = this.$refs.customerListContent.$el
    const contentAreaElement = document.querySelector('.content-area-wrapper')
    customerListContentElement.addEventListener('scroll', this.isScrollBottom)
    contentAreaElement.addEventListener('mouseover', this.setHoverElement)
  },
  beforeDestroy() {
    const contentAreaElement = document.querySelector('.content-area-wrapper')
    contentAreaElement.removeEventListener('mouseover', this.setHoverElement)
  },
  methods: {
    moment,
    // (獲取)本月
    getThisMonthRange() {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      // 设置日期对象
      const date = {
        start: firstDayOfMonth,
        end: lastDayOfMonth,
      }
      this.$refs.dateRangeCustomizeCreated.specifiedTime(date.start, date.end)
    },

    // (顯示)狀態是否選取
    isFilterAdminBrandUse(state) {
      if (!this.searchAdminBrand) return false

      if (typeof this.searchAdminBrand === 'number') {
        return this.searchAdminBrand === state
      }

      if (Array.isArray(this.searchAdminBrand)) {
        return this.searchAdminBrand.includes(state)
      }
      return false
    },

    // (點擊)狀態
    clickFilterBrandBtn(brand) {
      const isInArray = this.isFilterAdminBrandUse(brand)
      const resolveSearchAdminBrand = JSON.parse(JSON.stringify(this.searchAdminBrand))
      if (!isInArray) {
        if (resolveSearchAdminBrand) {
          if (typeof resolveSearchAdminBrand === 'number') {
            this.searchAdminBrand = [resolveSearchAdminBrand, brand]
          } else {
            resolveSearchAdminBrand.push(brand)
            this.searchAdminBrand = resolveSearchAdminBrand
          }
        }
        if (!resolveSearchAdminBrand) {
          this.searchAdminBrand = brand
        }
      }
      if (isInArray) {
        if (typeof resolveSearchAdminBrand === 'number') {
          if (resolveSearchAdminBrand === brand) {
            this.searchAdminBrand = null
          }
        } else {
          const index = resolveSearchAdminBrand.indexOf(brand)
          if (index !== -1) {
            resolveSearchAdminBrand.splice(index, 1)
            this.searchAdminBrand = resolveSearchAdminBrand.length > 0 ? resolveSearchAdminBrand : null
          }
        }
      }
    },

    targetRouteContent(id) {
      const targetRoute = { name: 'admin-customer-view', params: { id } }
      if (this.$route.name !== targetRoute.name || this.$route.params.id !== targetRoute.params.id) {
        this.$router.push(targetRoute)
      }
    },

    // (觸發)顯示待按詳情
    submitShowContent(id, customer) {
      this.$emit('open-chat', id, customer)
    },

    // (觸發)搜尋
    submitSearchContent(search) {
      this.perPage = 15
      const propertiesToCopy = [
        'sortBy',
        'searchQuery',
        'searchDepartment',
        'searchExecutionType',
        'searchType',
        'timeRange',
        'searchAdminBrand',
        'searchTimeWarning',
      ]
      // this.sortBy = 'priority'
      // const resolveCache = this.syncObject(this.customSearch, search)
      propertiesToCopy.forEach(property => {
        this[property] = search[property]
      })
    },

    // (觸發)更新滿版模式
    submitUpdateFullScreen() {
      localStorage.setItem('customerFullScreen', JSON.stringify(!this.$store.state.app.customerFullScreen))
      this.$store.commit('app/UPDATE_CUSTOMER_FULLSCREEN', !this.$store.state.app.customerFullScreen)
    },

    // ------------------------------------------------------------------------------------------------

    // (觸發)開新分頁
    // submitOpenCustomer() {
    //   const url = `/admin/customer/${this.rightClick.id}`
    //   window.open(url, '_blank')
    // },

    // (觸發)分享
    submitShareCustomer() {
      if (!navigator.share) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const url = `/admin/customer?fid=${this.rightClick.id}`
      navigator.share({
        title: '曠世國際管理後台',
        text: '我在客戶列表找到一位有趣的客戶，趕緊來看看吧',
        url,
      })
    },

    // (觸發)刪除
    submitDeleteCustomer(arr) {
      if (arr.length <= 0) return

      const [customerId] = arr

      const matchingCustomer = this.customerData.find(customerItem => customerItem.id === customerId)

      this.useSwalAlertWarning('刪除客戶', !matchingCustomer || arr.length > 1 ? `你確定要永久刪除所選的 ${arr.length} 個客戶 嗎?` : `你確定要永久刪除客戶 ${matchingCustomer.name ? matchingCustomer.name : '未命名客戶'} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setCustomerDelete({
              data: {
                customer_id: arr,
              },
            })
              .then(() => {
                this.refreshCustomerListPageData()
                if (arr.length === 1) {
                  if (customerId === this.activeChatContactId) this.$emit('clear-active-info-data')
                }
                this.useSwalAlertCenter(true, '刪除成功!', '客戶已移除')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)選取
    submitSelectedCustomer(id) {
      const indexInSelected = this.selectedList.indexOf(id)
      if (indexInSelected !== -1) {
        this.selectedList.splice(indexInSelected, 1)
      } else {
        this.selectedList.push(id)
      }
    },

    // (是否)選取
    isIndexInSelectedCustomer(id) {
      return this.selectedList.includes(id)
    },

    // (自動)加載資料
    isScrollBottom() {
      const { scrollTop, scrollHeight, clientHeight } = this.$refs.customerListContent.$el
      if (scrollTop + clientHeight === scrollHeight && this.isAll) {
        this.currentPage += 1
      }
    },

    // (開啟)創建時間篩選器
    openCreatedModel() {
      this.$bvModal.show('sidebarFilterCreated')
      setTimeout(() => { this.$refs.dateRangeCustomizeCreated.initDataTime() }, 200)
    },

    // (初始化)側欄
    getData() {
      // refetchData()
    },
    // ---------------------------------------

    // (偵測)滑鼠移動事件
    setHoverElement(event) {
      const path = event.composedPath()
      let isNode = false
      const openPopup = () => {
        this.openBusy = true
        this.openTimer = setTimeout(() => {
          this.openBusy = false
          if (this.hoverEl === null) return
          const customerIndex = this.hoverEl.id.split('-')[2]
          this.customerPopup = this.customerData[customerIndex]
          this.showPopup = true
          this.$nextTick(() => {
            const screenHeight = window.innerHeight
            this.$refs.customerPopup.style.top = ''
            this.$refs.customerPopup.style.bottom = ''
            if (this.hoverEl.getBoundingClientRect().top < screenHeight / 2) {
              this.$refs.customerPopup.style.top = '65px'
            } else {
              this.$refs.customerPopup.style.bottom = '0px'
            }
          })
        }, 500)
      }
      for (let i = 0; i < path.length; i += 1) {
        if (path[i].classList && path[i].classList.contains('popup')) {
          isNode = true
          this.closeBusy = false
          clearTimeout(this.closeTimer)
          return
        }
        if (path[i].classList && path[i].classList.contains('show-popup')) {
          isNode = true
          this.closeBusy = false
          if (this.hoverEl === path[i]) return
          this.hoverEl = path[i]
          this.closeBusy = false
          clearTimeout(this.closeTimer)
          if (this.openBusy) {
            this.openBusy = false
            clearTimeout(this.openTimer)
          }
          openPopup()
          return
        }
      }
      if (!isNode) {
        this.hoverEl = null
        if (this.showPopup && !this.closeBusy) {
          this.closeBusy = true
          this.closeTimer = setTimeout(() => {
            this.closeBusy = false
            this.showPopup = false
          }, 600)
        }
      }
    },

    // (跳轉)待辦列表
    targetRouteTodo(id) {
      const targetRoute = { name: 'admin-todo-view', params: { id } }
      const { href } = this.$router.resolve(targetRoute)
      window.open(href, '_blank')
    },

    // (打開)更新側欄
    openUpdateSidebar() {
      this.showPopup = false
      this.targetRouteContent(this.rightClick.id)
      this.submitShowContent(this.rightClick.id, this.rightClick)
      this.$emit('emit-update-sidebar')
    },
  },
  setup() {
    const searchCustomer = router.currentRoute.query

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      searchOptions,
    } = useCustomerSetting()

    const {
      isBusy,
      isBusyLoading,
      customerData,
      totalNum,
      perPage,
      sortBy,
      isSortDirDesc,
      currentPage,
      priorityOptions,
      AdminBrandOptions,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeOptions,
      searchAdminBrand,
      searchQuery,
      searchDepartment,
      searchExecutionType,
      searchType,
      searchTimeWarning,
      timeRange,
      timeWarningOptions,
      getCustomerListInitData,
      getCustomerListNextData,
      refreshCustomerListPageData,
      setCustomerUpdate,
      setCustomerDelete,
      setCustomerComplete,
      setCustomerReview,
      refetchData,
      selectedList,
      syncObject,
    } = useCustomerList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    if (searchCustomer.fid) {
      searchQuery.value = `#${searchCustomer.fid}`
    }

    getCustomerListInitData()

    return {
      perfectScrollbarSettings,

      isBusy,
      isBusyLoading,
      customerData,
      totalNum,
      perPage,
      sortBy,
      isSortDirDesc,
      currentPage,
      priorityOptions,
      AdminBrandOptions,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeOptions,
      searchAdminBrand,
      searchQuery,
      searchDepartment,
      searchExecutionType,
      searchType,
      searchTimeWarning,
      timeRange,
      timeWarningOptions,
      // useAudio,
      // useFullScreen,
      getCustomerListNextData,
      getCustomerListInitData,
      refreshCustomerListPageData,
      setCustomerUpdate,
      setCustomerDelete,
      setCustomerComplete,
      setCustomerReview,
      refetchData,
      selectedList,

      useAlertToast,
      useHttpCodeAlert,
      syncObject,

      searchOptions,

      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes
.popup {
  position: absolute;
  z-index: 3;
  left: calc(100% + 14px);
  width: 320px;
  min-height: 300px;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ebedef;
  overflow: hidden;
  // border: 1px solid #ccc;
  .header {
    display: flex;
    flex-direction: column;
    .banner {
      background-color: $primary; // rgb(116, 211, 231);
      height: 60px;
    }
    .top-section {
      display: flex;
      justify-content: space-between;
      height: 20px;
      .profile {
        position: absolute;
        top: 20px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ebedef;
        border-radius: 50%;
        width: 70px;
        height: 70px;
      }
    }
  }
  .body {
    padding: 16px;
    .info-card {
      display: flex;
      flex-direction: column;
      flex: 0 1 385px;
      height: 300px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 10px;
      background-color: #fff;
      // overflow: hidden;
    }
    .last-todo-card {
      position: relative;
      top: 4px;
      cursor: pointer;
      text-decoration: none;
      z-index: 0;
      border-radius: 6px;
      background-color: #adadad30;
      transition: all 0.3s ease-out;
      .card-info {
        position: relative;
        padding: 10px;
      }
      &:hover {
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: 0px;
        border: 1px solid #cccccc;
        background-color: white;
      }
    }
  }
  @media (max-width: 840px) {
    left: 70px;
    width: 360px;
  }
  @media (max-width: 576px) {
    display: none !important;
  }
}
.dark-layout {
  .popup {
    background-color: #232428;
    .header {
      // .banner {
      //   // background-color: $theme-dark-body-bg;
      // }
      .top-section {
        .profile {
          background-color: #232428;
        }
      }
    }
    .body {
      .info-card {
        background-color: $theme-dark-card-bg;
      }
      .last-todo-card {
        .card-top {
          background-color: #26272b;
        }
        .card-info {
          box-shadow: 0 4px 8px #3a3a3a66;
        }
      }
    }
  }
}

.filter-dot {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 9px;
  width: 9px;
  z-index: 10;
  background-color: var(--text-color);
  // box-shadow: 0 0 5px 2px var(--text-color);
  // border-radius: 50%;
  // padding: 2px;
  // margin-right: 5px;
}

.todo-text {
  word-break: break-all;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.5; /* 你想要的行高，可以自行調整 */
  overflow: hidden;
}

.customer-link-btn {
  transition: transform ease-out 200ms;
  // padding: 5px;
  border-radius: 5px;
  .customer-link-btn-image {
    width: 22px;
    height: 22px;
  }

  &:hover {
    cursor: pointer;
    .customer-link-btn-image {
      transform: scale(1.1);
      // animation: btn-breathe .8s linear infinite;
    }
  }
}

.chat-contact {
  position: relative;
  display: flex;
  align-items: center;
  height: 65px;
}

.context-menu-title {
  padding: 0.26rem 1rem;
  max-width: 150px;
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.context-menu-btn {
  transition: transform ease-out 200ms;
  // padding: 5px;
  border-radius: 5px;
  .context-menu-btn-image {
    width: 18px;
    height: 18px;
  }

  &:hover {
    cursor: pointer;
    // background-color: #a4a9b080;
    .context-menu-btn-image {
      transform: scale(1.05);
      animation: btn-breathe .8s linear infinite;
    }
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // display: flex;
  // align-items: center;
  .dropdown-item-btn-image {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-customer-dropdown .8s linear infinite;
    }
  }
}

.sidebar-search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  // height: 100%;
  height: calc(100% - 70px);
}

.sidebar-search-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.icon-link-btn {
  .icon-link-btn-image {
    height: 25px;
    width: 25px;
    &:hover {
      animation: rotateAnimation 3s linear infinite;
    }
  }
}

.icon-expand-link-btn {
  .icon-expand-link-btn-image {
    height: 25px;
    width: 25px;
  }
  &:hover {
    animation: btn-breathe-customer-dropdown .8s linear infinite;
  }
}

.search-icon-div {
  transition: transform 0.5s ease-in-out;
  .search-icon:hover {
    // background-color: red;
    transform: scale(1.2);
  }
}

.sidebar-search-footer {
  width: 100%;
  border-top: 1px #a4a9b080 solid;
}

.model-header {
  color: white;
  padding: 3px 0;
}
.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
@keyframes btn-breathe-customer-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
</style>
